import * as Yup from 'yup';
import { StageType } from '../../../../../graphql/types';
import { defaultUnits } from '../../../../sustell_15/utils/unit-utils';
import { usedResourcesPart } from './baselineValidationSchemaGeneralPart';
import {
  numericOptionalWithGreaterThanMin,
  numericOptionalWithMin,
  numericOptionalWithMinMax,
  numericRequiredWithGreaterThanMin,
  numericRequiredWithMin,
  numericRequiredWithMinMax,
} from './validationObjectBuilderFunctions';
import {
  availableMmsTypeForMonthlyStoragePeriods,
  availableMmsTypeForOverOrUnderStoragePeriod,
} from '../../../../sustell_15/models/Baseline/BeefBaseline';
import { processingStageData } from './processingBaselineValidation';
import { FacilitySpecies } from '../../../../sustell_15/models/Facility/FacilityTypes';

const outputCowSumValidation = (intl) =>
  numericRequiredWithMin(intl, 0).test(
    'outputCowSum',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { parent } = testContext;

      const cowSum = (isNaN(parent.cowsToAnotherStage) ? 0 : Number(parent.cowsToAnotherStage))
        + (isNaN(parent.cowsSold) ? 0 : Number(parent.cowsSold));

      if (cowSum <= 0) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.OUTPUT.SUM_OF_COW.GREATER_THAN_ZERO',
          }),
        });
      }

      return true;
    }
  );

const outputCalvesSumValidation = (intl) =>
  numericRequiredWithMin(intl, 0).test(
    'outputCalvesSum',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { parent } = testContext;

      const cowSum = (isNaN(parent.weanedCalvesToAnotherStage) ? 0 : Number(parent.weanedCalvesToAnotherStage))
        + (isNaN(parent.weanedCalvesSold) ? 0 : Number(parent.weanedCalvesSold));

      if (cowSum <= 0) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.OUTPUT.SUM_OF_CALVES.GREATER_THAN_ZERO',
          }),
        });
      }

      return true;
    }
  );

const averageWeightOutputValidation = (intl) =>
  numericRequiredWithGreaterThanMin(intl, 0).test(
    'averageWeightOutput',
    '',
    function (value, testContext) {
      const { path, createError } = this;
      const { from } = testContext;

      const averageWeightInput = from[1].value.input.averageWeightNewAnimals ?? 0;

      if (value <= averageWeightInput) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT.GREATER_THAN_AVERAGE_WEIGHT_INPUT',
          }),
        });
      }

      return true;
    }
  );

const averageCalvesWeightOutputValidation = (intl) =>
  numericRequiredWithGreaterThanMin(intl, 0).test(
    'averageCalvesWeightOutput',
    '',
    function (value, testContext) {
      const { path, createError } = this;
      const { from } = testContext;

      const averageWeightInput = from[1].value.input.averageWeightAtBirth ?? 0;

      if (value <= averageWeightInput) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT.GREATER_THAN_AVERAGE_WEIGHT_BIRTH_INPUT',
          }),
        });
      }

      return true;
    }
  );

const averageAgeOutputValidation = (intl) =>
  numericRequiredWithMin(intl, 0).test(
    'averageAgeOutput',
    '',
    function (value, testContext) {
      const { path, createError } = this;
      const { from } = testContext;

      const averageAgeInput = from[1].value.input.averageAgeAtStart ?? 0;

      if (value <= averageAgeInput) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_AGE.GREATER_THAN_AVERAGE_AGE_INPUT',
          }),
        });
      }

      return true;
    }
  );

const cowMortalityGrowingValidation = (intl) =>
  numericRequiredWithMin(intl, 0).test(
    'cowMortalityGrowing',
    '',
    function (value, testContext) {
      const { path, createError } = this;
      const { from } = testContext;

      const internalSources = from[1].value.input.internalSources;
      const externalSources = from[1].value.input.externalSources;

      let animalsSum = 0;

      if (internalSources) {
        internalSources.forEach((source) => {
          animalsSum += source?.numberAnimals ? Number(source.numberAnimals) : 0;
        })
      }

      if (externalSources) {
        externalSources.forEach((source) => {
          animalsSum += source?.numberAnimals ? Number(source.numberAnimals) : 0;
        })
      }

      if (value >= animalsSum) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.OUTPUT.COW_MORTALITY.LESS_THAN_NEW_ANIMALS',
          }),
        });
      }

      return true;
    }
  );

const cowMortalityBreedingValidation = (intl) =>
  numericRequiredWithMin(intl, 0).test(
    'cowMortalityBreeding',
    '',
    function (value, testContext) {
      const { path, createError } = this;
      const { from } = testContext;

      const internalSources = from[1].value.input.internalSources;
      const externalSources = from[1].value.input.externalSources;

      let animalsSum = 0;

      if (internalSources) {
        internalSources.forEach((source) => {
          animalsSum += source?.numberAnimals ? Number(source.numberAnimals) : 0;
        })
      }

      if (externalSources) {
        externalSources.forEach((source) => {
          animalsSum += source?.numberAnimals ? Number(source.numberAnimals) : 0;
        })
      }

      const animalsStartDate = from[1].value.input.animalsPresentAtStart
        ? Number(from[1].value.input.animalsPresentAtStart)
        : 0;
      const animalsEndDate = from[1].value.input.animalsPresentAtEnd
        ? Number(from[1].value.input.animalsPresentAtEnd)
        : 0;

      let animalsPresentSum = (animalsStartDate + animalsEndDate) / 2;

      let checkValue = animalsSum > animalsPresentSum ? animalsSum : animalsPresentSum;

      if (value >= checkValue) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.OUTPUT.COW_MORTALITY_BREEDING.LESS_THAN_NEW_ANIMALS',
          }),
        });
      }

      return true;
    }
  );

const calvesMortalityBreedingValidation = (intl) =>
  numericRequiredWithMin(intl, 0).test(
    'calvesMortalityBreeding',
    '',
    function (value, testContext) {
      const { path, createError } = this;
      const { parent } = testContext;

      const calvesStage = isNaN(parent.weanedCalvesToAnotherStage)
        ? 0 : Number(parent.weanedCalvesToAnotherStage);

      const checkValue = checkValue === 0 ? (value > calvesStage) : (value >= calvesStage);

      if (checkValue) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.OUTPUT.CALVES_MORTALITY_BREEDING.LESS_THAN_NEW_ANIMALS',
          }),
        });
      }

      return true;
    }
  );

const averageWeightMortalityOutputValidation = (intl) =>
  numericRequiredWithMin(intl, 0).test(
    'averageWeightMortalityOutput',
    '',
    function (value, testContext) {
      const { path, createError } = this;
      const { parent, from } = testContext;

      const averageWeightOutput = parent.averageWeightOfCowsLeavingTheStage ?? 0;
      const averageWeightInput = from[1].value.input.averageWeightNewAnimals ?? 0;

      if (value < averageWeightInput || value > averageWeightOutput) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT_MORTALITY.BETWEEN_AVERAGE_WEIGHTS',
          }),
        });
      }

      return true;
    }
  );

const averageWeightCalvesMortalityOutputValidation = (intl) =>
  numericRequiredWithMin(intl, 0).test(
    'averageWeightCalvesMortalityOutput',
    '',
    function (value, testContext) {
      const { path, createError } = this;
      const { parent, from } = testContext;

      const averageWeightOutput = parent.averageWeightOfCalvesLeavingTheStage ?? 0;
      const averageWeightInput = from[1].value.input.averageWeightAtBirth ?? 0;

      if (value < averageWeightInput || value > averageWeightOutput) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.OUTPUT.CALVES_AVERAGE_WEIGHT_MORTALITY.BETWEEN_AVERAGE_WEIGHTS',
          }),
        });
      }

      return true;
    }
  );

const feedSumValidation = (intl) =>
  numericOptionalWithMin(intl, 0).test(
    'feedSum',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { from } = testContext;

      const compoundFeeds = from[1].value.compoundFeeds;
      const singleIngredients = from[1].value.singleIngredients;
      const freshGrass = from[1].value.freshGrass;

      let feedSum = 0;

      if (compoundFeeds) {
        compoundFeeds.forEach((feed) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (singleIngredients) {
        singleIngredients.forEach((feed) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (freshGrass) {
        freshGrass.forEach((feed) => {
          feedSum += feed?.amount ? Number(feed.amount) : 0;
        })
      }

      if (feedSum <= 0) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.FEED.SUM_OF_FEED.GREATER_THAN_ZERO',
          }),
        });
      }

      return true;
    }
  );

const feedRequiredSumValidation = (intl) =>
  numericRequiredWithMin(intl, 0).test(
    'feedRequiredSum',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { from } = testContext;

      const compoundFeeds = from[1].value.compoundFeeds;
      const singleIngredients = from[1].value.singleIngredients;
      const freshGrass = from[1].value.freshGrass;

      let feedSum = 0;

      if (compoundFeeds) {
        compoundFeeds.forEach((feed) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (singleIngredients) {
        singleIngredients.forEach((feed) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (freshGrass) {
        freshGrass.forEach((feed) => {
          feedSum += feed?.amount ? Number(feed.amount) : 0;
        })
      }

      if (feedSum <= 0) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.FEED.SUM_OF_FEED.GREATER_THAN_ZERO',
          }),
        });
      }

      return true;
    }
  );

const feedSumBreedingValidation = (intl) =>
  numericOptionalWithMin(intl, 0).test(
    'feedSumBreeding',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { from } = testContext;

      const compoundFeeds = from[1].value.compoundFeeds;
      const singleIngredients = from[1].value.singleIngredients;
      const freshGrass = from[1].value.freshGrass;
      const mothersMilk = from[1].value.mothersMilk;

      let feedSum = 0;

      if (compoundFeeds) {
        compoundFeeds.forEach((feed) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (singleIngredients) {
        singleIngredients.forEach((feed) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (freshGrass) {
        freshGrass.forEach((feed) => {
          feedSum += feed?.amount ? Number(feed.amount) : 0;
        })
      }

      if (mothersMilk) {
        feedSum += mothersMilk.amount ? Number(mothersMilk.amount) : 0;
      }

      if (feedSum <= 0) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.FEED.SUM_OF_FEED.GREATER_THAN_ZERO',
          }),
        });
      }

      return true;
    }
  );

const feedRequiredSumBreedingValidation = (intl) =>
  numericRequiredWithMin(intl, 0).test(
    'feedRequiredSumBreeding',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { from } = testContext;

      const compoundFeeds = from[1].value.compoundFeeds;
      const singleIngredients = from[1].value.singleIngredients;
      const freshGrass = from[1].value.freshGrass;
      const mothersMilk = from[1].value.mothersMilk;

      let feedSum = 0;

      if (compoundFeeds) {
        compoundFeeds.forEach((feed) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (singleIngredients) {
        singleIngredients.forEach((feed) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (freshGrass) {
        freshGrass.forEach((feed) => {
          feedSum += feed?.amount ? Number(feed.amount) : 0;
        })
      }

      if (mothersMilk) {
        feedSum += mothersMilk.amount ? Number(mothersMilk.amount) : 0;
      }

      if (feedSum <= 0) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.FEED.SUM_OF_FEED.GREATER_THAN_ZERO',
          }),
        });
      }

      return true;
    }
  );

const housingTimeValidation = (intl) =>
  numericRequiredWithMinMax(intl, 0, 100).test(
    'housingTime',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { parent } = testContext;

      const timeSum = (Number.isNaN(parent.timeInHousing) ? 0 : Number(parent.timeInHousing))
        + (Number.isNaN(parent.timeInFeedlot) ? 0 : Number(parent.timeInFeedlot))
        + (Number.isNaN(parent.timeGrazingLargeAreas) ? 0 : Number(parent.timeGrazingLargeAreas))
        + (Number.isNaN(parent.timeGrazingPastures) ? 0 : Number(parent.timeGrazingPastures))

      if (timeSum !== 100) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.BEEF.HOUSING.TIME_SUM_MUST_BE_100',
          }),
        });
      }

      return true;
    }
  );

const isStoragePeriodRequired = (selectedMmsType) =>
  availableMmsTypeForMonthlyStoragePeriods.some(
    (mmsType) => mmsType === selectedMmsType
  ) ||
  availableMmsTypeForOverOrUnderStoragePeriod.some(
    (mmsType) => mmsType === selectedMmsType
  );

const haveMoreThanOneMms = (from) => {
  if (from) {
    return from[1].value.manureSystems.length > 1;
  }

  return false;
};

const manureShareValidation = (intl) =>
  numericRequiredWithMin(intl, 0).test(
    'manureShare',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { from } = testContext;

      if (from) {
        let shareSum = 0;

        from[1].value.manureSystems?.forEach((mms) => {
          shareSum += mms?.share ? Number(mms.share) : 0;
        });

        if (shareSum !== 100) {
          return createError({
            path,
            message: intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.HOUSING.SHARE_SUM_MUST_BE_100',
            }),
          });
        }
      }

      return true;
    }
  );

const internalSourcesValidation = (intl) =>
  Yup.array().of(
    Yup.object({
      animalSystemType: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      farmId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      originStageId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      animalType: Yup.string()
        .when('stageType', {
          is: (value) => value === StageType.Breeding,
          then: Yup.string().required(
            intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
          ),
          otherwise: Yup.string()
        }),
      numberAnimals: numericRequiredWithGreaterThanMin(intl, 0)
    })
  ).min(1);

const externalSourcesValidation = (intl) =>
  Yup.array().of(
    Yup.object({
      numberAnimals: numericRequiredWithGreaterThanMin(intl, 0)
    })
  ).min(1);


const internalSourcesBreedingValidation = (intl) =>
  Yup.array().of(
    Yup.object({
      farmId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      originStageId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      numberAnimals: numericRequiredWithGreaterThanMin(intl, 0)
    })
  ).min(1);

export const stageInputGrowing = (intl) =>
  Yup.object().shape({
    startDate: Yup.date().typeError(
      intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' })
    ),
    endDate: Yup.date()
      .typeError(intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' }))
      .min(
        Yup.ref('startDate'),
        intl.formatMessage({ id: 'VALIDATION.DATE.RANGE_ERROR' })
      ),
    isStageRepeated: Yup.string(),
    numberOfRepetitions: Yup.number().when('isStageRepeated', {
      is: (isStageRepeated) =>
        isStageRepeated && isStageRepeated === "Yes",
      then: numericOptionalWithGreaterThanMin(intl, 0),
      otherwise: numericOptionalWithMin(intl, 0),
    }),
    growingPurpose: Yup.string().required(
      intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
    ),
    averageAgeAtStart: numericRequiredWithMin(intl, 0),
    averageWeightNewAnimals: numericRequiredWithMin(intl, 0),
    internalSources: Yup.array().when('externalSources', {
      is: (externalSources) =>
        externalSources === undefined ||
        (Array.isArray(externalSources) &&
          !externalSources.some(
            (item) => !Number.isNaN(item.numberAnimals) && item.numberAnimals > 0
          )),
      then: internalSourcesValidation(intl),
    }),
    externalSources: Yup.array().when('internalSources', {
      is: (internalSources) =>
        internalSources === undefined ||
        (Array.isArray(internalSources) &&
          !internalSources.some(
            (item) => !Number.isNaN(item.numberAnimals) && item.numberAnimals > 0
          )),
      then: externalSourcesValidation(intl),
    }),
  },
    [['internalSources', 'externalSources'],
    ['animalsPresentAtStart', 'animalsPresentAtEnd', 'averageWeightAtStart']]
  ); // to avoid circular dependency error

export const stageInputBreeding = (intl) =>
  Yup.object().shape({
    startDate: Yup.date().typeError(
      intl.formatMessage({ id: 'VALIDATION.DATE.INPUT' })
    ),
    animalsPresentAtStart: numericRequiredWithGreaterThanMin(intl, 0),
    animalsPresentAtEnd: numericRequiredWithGreaterThanMin(intl, 0),
    averageWeightAtStart: numericRequiredWithGreaterThanMin(intl, 0),
    averageWeightNewAnimals: numericRequiredWithGreaterThanMin(intl, 0),
    internalSources: Yup.array().when('externalSources', {
      is: (externalSources) =>
        externalSources === undefined ||
        (Array.isArray(externalSources) &&
          !externalSources.some(
            (item) => !Number.isNaN(item.numberAnimals) && item.numberAnimals > 0
          )),
      then: internalSourcesBreedingValidation(intl),
    }),
    externalSources: Yup.array().when('internalSources', {
      is: (internalSources) =>
        internalSources === undefined ||
        (Array.isArray(internalSources) &&
          !internalSources.some(
            (item) => !Number.isNaN(item.numberAnimals) && item.numberAnimals > 0
          )),
      then: externalSourcesValidation(intl),
    }),
    averageWeightAtBirth: numericOptionalWithMin(intl, 0),
    permanencePeriod: numericRequiredWithGreaterThanMin(intl, 0)
  },
    [['internalSources', 'externalSources'],
    ['animalsPresentAtStart', 'animalsPresentAtEnd', 'averageWeightAtStart']]
  ); // to avoid circular dependency error

export const stageFeedGrowing = (intl) =>
  Yup.object().shape({
    dietCharacterisation: Yup.string()
      .required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })),
    compoundFeeds: Yup.array().of(
      Yup.object({
        feedType: Yup.string(),
        kgPerAnimal: Yup.number()
          .when('feedType', {
            is: (value) => value,
            then: feedRequiredSumValidation(intl),
            otherwise: feedSumValidation(intl)
          })
      }),
    ),
    singleIngredients: Yup.array().of(
      Yup.object({
        feedType: Yup.string(),
        kgPerAnimal: Yup.number()
          .when('feedType', {
            is: (value) => value,
            then: feedRequiredSumValidation(intl),
            otherwise: feedSumValidation(intl),
          }),
        origin: Yup.string()
          .when('feedType', {
            is: (value) => value,
            then: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })),
            otherwise: Yup.string(),
          })
      }),
    ),
    freshGrass: Yup.array().of(
      Yup.object({
        type: Yup.string(),
        amount: Yup.number()
          .when('type', {
            is: (value) => value,
            then: feedRequiredSumValidation(intl),
            otherwise: feedSumValidation(intl)
          }),
        digestibility: Yup.number()
          .when('type', {
            is: (value) => value,
            then: numericRequiredWithMin(intl, 0),
            otherwise: numericOptionalWithMin(intl, 0)
          }),
        crudeProteinContent: Yup.number()
          .when('type', {
            is: (value) => value,
            then: numericRequiredWithMin(intl, 0),
            otherwise: numericOptionalWithMin(intl, 0)
          }),
        dryMatterContent: Yup.number()
          .when('type', {
            is: (value) => value,
            then: numericRequiredWithMin(intl, 0),
            otherwise: numericOptionalWithMin(intl, 0)
          }),
        grossEnergyContent: numericOptionalWithMinMax(intl, 0, 25)
      }),
    )
  });

export const stageFeedBreeding = (intl) =>
  Yup.object({
    cows: Yup.object().shape(
      {
        dietCharacterisation: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })),
        compoundFeeds: Yup.array().of(
          Yup.object({
            feedType: Yup.string(),
            kgPerAnimal: Yup.number()
              .when('feedType', {
                is: (value) => value,
                then: feedRequiredSumValidation(intl),
                otherwise: feedSumValidation(intl)
              })
          }),
        ),
        singleIngredients: Yup.array().of(
          Yup.object({
            feedType: Yup.string(),
            kgPerAnimal: Yup.number()
              .when('feedType', {
                is: (value) => value,
                then: feedRequiredSumValidation(intl),
                otherwise: feedSumValidation(intl),
              }),
            origin: Yup.string()
              .when('feedType', {
                is: (value) => value,
                then: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })),
                otherwise: Yup.string(),
              })
          }),
        ),
        freshGrass: Yup.array().of(
          Yup.object({
            type: Yup.string(),
            amount: Yup.number()
              .when('type', {
                is: (value) => value,
                then: feedRequiredSumValidation(intl),
                otherwise: feedSumValidation(intl)
              }),
            digestibility: Yup.number()
              .when('type', {
                is: (value) => value,
                then: numericRequiredWithMin(intl, 0),
                otherwise: numericOptionalWithMin(intl, 0)
              }),
            crudeProteinContent: Yup.number()
              .when('type', {
                is: (value) => value,
                then: numericRequiredWithMin(intl, 0),
                otherwise: numericOptionalWithMin(intl, 0)
              }),
            dryMatterContent: Yup.number()
              .when('type', {
                is: (value) => value,
                then: numericRequiredWithMin(intl, 0),
                otherwise: numericOptionalWithMin(intl, 0)
              }),
            grossEnergyContent: numericOptionalWithMinMax(intl, 0, 25)
          }),
        )
      }
    ),
    calves: Yup.object().shape(
      {
        dietCharacterisation: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })),
        averageMilkFat: numericOptionalWithMinMax(intl, 0, 100),
        compoundFeeds: Yup.array().of(
          Yup.object({
            feedType: Yup.string(),
            kgPerAnimal: Yup.number()
              .when('feedType', {
                is: (value) => value,
                then: feedRequiredSumBreedingValidation(intl),
                otherwise: feedSumBreedingValidation(intl)
              })
          }),
        ),
        singleIngredients: Yup.array().of(
          Yup.object({
            feedType: Yup.string(),
            kgPerAnimal: Yup.number()
              .when('feedType', {
                is: (value) => value,
                then: feedRequiredSumBreedingValidation(intl),
                otherwise: feedSumBreedingValidation(intl),
              }),
            origin: Yup.string()
              .when('feedType', {
                is: (value) => value,
                then: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })),
                otherwise: Yup.string(),
              })
          }),
        ),
        freshGrass: Yup.array().of(
          Yup.object({
            type: Yup.string(),
            amount: Yup.number()
              .when('type', {
                is: (value) => value,
                then: feedRequiredSumBreedingValidation(intl),
                otherwise: feedSumBreedingValidation(intl)
              }),
            digestibility: Yup.number()
              .when('type', {
                is: (value) => value,
                then: numericRequiredWithMin(intl, 0),
                otherwise: numericOptionalWithMin(intl, 0)
              }),
            crudeProteinContent: Yup.number()
              .when('type', {
                is: (value) => value,
                then: numericRequiredWithMin(intl, 0),
                otherwise: numericOptionalWithMin(intl, 0)
              }),
            dryMatterContent: Yup.number()
              .when('type', {
                is: (value) => value,
                then: numericRequiredWithMin(intl, 0),
                otherwise: numericOptionalWithMin(intl, 0)
              }),
            grossEnergyContent: numericOptionalWithMinMax(intl, 0, 25)
          }),
        ),
        mothersMilk: Yup.object({
          amount: feedSumBreedingValidation(intl),
          digestibility: Yup.number()
            .when('amount', {
              is: (value) => value,
              then: numericRequiredWithMin(intl, 0),
              otherwise: numericOptionalWithMin(intl, 0)
            }),
          crudeProteinContent: Yup.number()
            .when('amount', {
              is: (value) => value,
              then: numericRequiredWithMin(intl, 0),
              otherwise: numericOptionalWithMin(intl, 0)
            }),
          dryMatterContent: Yup.number()
            .when('amount', {
              is: (value) => value,
              then: numericRequiredWithMin(intl, 0),
              otherwise: numericOptionalWithMin(intl, 0)
            }),
        })
      }
    )
  });

export const stageHousingGrowing = (intl) =>
  Yup.object({
    timeInHousing: housingTimeValidation(intl),
    timeInFeedlot: housingTimeValidation(intl),
    timeGrazingLargeAreas: housingTimeValidation(intl),
    timeGrazingPastures: housingTimeValidation(intl),
    manureSystems: Yup.array().when('timeInHousing', {
      is: (value) => value > 0,
      then: Yup.array()
        .of(
          Yup.object({
            mmsType: Yup.string().required(
              intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
            ),
            storagePeriod: Yup.string().when('mmsType', {
              is: (val) => isStoragePeriodRequired(val),
              then: Yup.string().required(
                intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
              ),
              otherwise: Yup.string(),
            }),
            share: Yup.lazy((_, { from }) =>
              haveMoreThanOneMms(from)
                ? manureShareValidation(intl)
                : numericOptionalWithMin(intl, 0)
            ),
          })
        )
        .min(1, intl.formatMessage({ id: 'SUSTELL.HOUSING.MMS.MIN1.ERROR' })),
    }),
    beddingSystems: Yup.array().of(
      Yup.object({
        beddingType: Yup.string(),
        beddingAmount: Yup.number().when('beddingType', {
          is: (val) => val,
          then: numericRequiredWithGreaterThanMin(intl, 0),
          otherwise: numericOptionalWithGreaterThanMin(intl, 0),
        }),
      })
    ),
  });

export const stageHousingBreeding = (intl) =>
  Yup.object({
    cows: Yup.object().shape({
      timeInHousing: housingTimeValidation(intl),
      timeInFeedlot: housingTimeValidation(intl),
      timeGrazingLargeAreas: housingTimeValidation(intl),
      timeGrazingPastures: housingTimeValidation(intl),
      manureSystems: Yup.array().when('timeInHousing', {
        is: (value) => value > 0,
        then: Yup.array()
          .of(
            Yup.object({
              mmsType: Yup.string().required(
                intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
              ),
              storagePeriod: Yup.string().when('mmsType', {
                is: (val) => isStoragePeriodRequired(val),
                then: Yup.string().required(
                  intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
                ),
                otherwise: Yup.string(),
              }),
              share: Yup.lazy((_, { from }) =>
                haveMoreThanOneMms(from)
                  ? manureShareValidation(intl)
                  : numericOptionalWithMin(intl, 0)
              ),
            })
          )
          .min(1, intl.formatMessage({ id: 'SUSTELL.HOUSING.MMS.MIN1.ERROR' })),
      }),
    }),
    calves: Yup.object().shape({
      timeInHousing: housingTimeValidation(intl),
      timeInFeedlot: housingTimeValidation(intl),
      timeGrazingLargeAreas: housingTimeValidation(intl),
      timeGrazingPastures: housingTimeValidation(intl),
      manureSystems: Yup.array().when('timeInHousing', {
        is: (value) => value > 0,
        then: Yup.array()
          .of(
            Yup.object({
              mmsType: Yup.string().required(
                intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
              ),
              storagePeriod: Yup.string().when('mmsType', {
                is: (val) => isStoragePeriodRequired(val),
                then: Yup.string().required(
                  intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
                ),
                otherwise: Yup.string(),
              }),
              share: Yup.lazy((_, { from }) =>
                haveMoreThanOneMms(from)
                  ? manureShareValidation(intl)
                  : numericOptionalWithMin(intl, 0)
              ),
            })
          )
          .min(1, intl.formatMessage({ id: 'SUSTELL.HOUSING.MMS.MIN1.ERROR' })),
      }),
    }),
    beddingSystems: Yup.array().of(
      Yup.object({
        beddingType: Yup.string(),
        beddingAmount: Yup.number().when('beddingType', {
          is: (val) => val,
          then: numericRequiredWithGreaterThanMin(intl, 0),
          otherwise: numericOptionalWithGreaterThanMin(intl, 0),
        }),
      })
    ),
  });

export const stageOutputGrowing = (intl) =>
  Yup.object({
    cowsToAnotherStage: outputCowSumValidation(intl),
    averageWeightOfCowsLeavingTheStage: averageWeightOutputValidation(intl),
    cowsSold: outputCowSumValidation(intl),
    averageAge: averageAgeOutputValidation(intl),
    numberOfMortalitiesCows: cowMortalityGrowingValidation(intl),
    averageWeightAtMortalityCows: averageWeightMortalityOutputValidation(intl),
  });

export const stageOutputBreeding = (intl) =>
  Yup.object({
    cowsToAnotherStage: outputCowSumValidation(intl),
    averageWeightOfCowsLeavingTheStage: numericRequiredWithGreaterThanMin(intl, 0),
    cowsSold: outputCowSumValidation(intl),
    numberOfMortalitiesCows: cowMortalityBreedingValidation(intl),
    averageWeightAtMortalityCows: averageWeightMortalityOutputValidation(intl),

    weanedCalvesToAnotherStage: outputCalvesSumValidation(intl),
    averageWeightOfCalvesLeavingTheStage: averageCalvesWeightOutputValidation(intl),
    weanedCalvesSold: outputCalvesSumValidation(intl),
    numberOfMortalitiesCalves: calvesMortalityBreedingValidation(intl),
    averageWeightAtMortalityCalves: averageWeightCalvesMortalityOutputValidation(intl),
  });

export const stageEmissionsGrowing = (intl) =>
  Yup.object({
    methaneEntericFermentation: numericOptionalWithMinMax(intl, -100, 100),
    nmvoc: numericOptionalWithMinMax(intl, -100, 100),
    methaneMms: numericOptionalWithMinMax(intl, -100, 100),
    nitrousOxideLeaching: numericOptionalWithMinMax(intl, -100, 100),
    nitrousOxideDirect: numericOptionalWithMinMax(intl, -100, 100),
    nitrousOxideVolatilization: numericOptionalWithMinMax(intl, -100, 100),
    nitrogenOxidesStorage: numericOptionalWithMinMax(intl, -100, 100),
    ammoniaStorage: numericOptionalWithMinMax(intl, -100, 100),
    ammoniaHousing: numericOptionalWithMinMax(intl, -100, 100),
    ammoniaYard: numericOptionalWithMinMax(intl, -100, 100),
    pm2_5: numericOptionalWithMinMax(intl, -100, 100),
    pm10: numericOptionalWithMinMax(intl, -100, 100),
    tsp: numericOptionalWithMinMax(intl, -100, 100),
  });

export const stageEmissionsBreeding = (intl) =>
  Yup.object({
    cows: Yup.object().shape({
      methaneEntericFermentation: numericOptionalWithMinMax(intl, -100, 100),
      nmvoc: numericOptionalWithMinMax(intl, -100, 100),
      methaneMms: numericOptionalWithMinMax(intl, -100, 100),
      nitrousOxideLeaching: numericOptionalWithMinMax(intl, -100, 100),
      nitrousOxideDirect: numericOptionalWithMinMax(intl, -100, 100),
      nitrousOxideVolatilization: numericOptionalWithMinMax(intl, -100, 100),
      nitrogenOxidesStorage: numericOptionalWithMinMax(intl, -100, 100),
      ammoniaStorage: numericOptionalWithMinMax(intl, -100, 100),
      ammoniaHousing: numericOptionalWithMinMax(intl, -100, 100),
      ammoniaYard: numericOptionalWithMinMax(intl, -100, 100),
      pm2_5: numericOptionalWithMinMax(intl, -100, 100),
      pm10: numericOptionalWithMinMax(intl, -100, 100),
      tsp: numericOptionalWithMinMax(intl, -100, 100),
    }),
    calves: Yup.object().shape({
      methaneEntericFermentation: numericOptionalWithMinMax(intl, -100, 100),
      nmvoc: numericOptionalWithMinMax(intl, -100, 100),
      methaneMms: numericOptionalWithMinMax(intl, -100, 100),
      nitrousOxideLeaching: numericOptionalWithMinMax(intl, -100, 100),
      nitrousOxideDirect: numericOptionalWithMinMax(intl, -100, 100),
      nitrousOxideVolatilization: numericOptionalWithMinMax(intl, -100, 100),
      nitrogenOxidesStorage: numericOptionalWithMinMax(intl, -100, 100),
      ammoniaStorage: numericOptionalWithMinMax(intl, -100, 100),
      ammoniaHousing: numericOptionalWithMinMax(intl, -100, 100),
      ammoniaYard: numericOptionalWithMinMax(intl, -100, 100),
      pm2_5: numericOptionalWithMinMax(intl, -100, 100),
      pm10: numericOptionalWithMinMax(intl, -100, 100),
      tsp: numericOptionalWithMinMax(intl, -100, 100),
    })
  });

// stage fields validation rules
export const stageDataPartBeef = ({ intl, userUOM = defaultUnits }) =>
  Yup.object({
    stages: Yup.array()
      .of(
        Yup.object({
          id: Yup.string(),
          name: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
            .min(
              3,
              intl.formatMessage(
                { id: 'VALIDATION.FIELD.MIN_LENGTH' },
                { count: 3 }
              )
            ),
          type: Yup.string()
            .oneOf([
              StageType.Breeding,
              StageType.Growing,
              StageType.Processing,
            ])
            .required(),
          stageData: Yup.object()
            .when('type', {
              is: StageType.Breeding,
              then: Yup.object({
                input: stageInputBreeding(intl),
                housing: stageHousingBreeding(intl),
                feed: stageFeedBreeding(intl),
                output: stageOutputBreeding(intl),
                emissions: stageEmissionsBreeding(intl),
              }),
            })
            .when('type', {
              is: StageType.Growing,
              then: Yup.object({
                input: stageInputGrowing(intl),
                housing: stageHousingGrowing(intl),
                feed: stageFeedGrowing(intl),
                output: stageOutputGrowing(intl),
                emissions: stageEmissionsGrowing(intl),
              }),
            })
            .when('type', {
              is: StageType.Processing,
              then: processingStageData(intl, FacilitySpecies.Beef),
            }),
        })
      )
      .required()
      .min(1, intl.formatMessage({ id: 'SUSTELL.STAGE.MIN.REQUIRED' })),
  });

// merge all necessary parts to baseSchema
const assembleValidationSchemaSustell = (baseSchema, confObj) => {
  const infoObject = baseSchema;
  const combinedSchema = Yup.object({ info: infoObject })
    .concat(Yup.object({ resourceUse: usedResourcesPart(confObj) }))
    .concat(stageDataPartBeef(confObj));
  return combinedSchema;
};

export default assembleValidationSchemaSustell;
