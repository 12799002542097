import { Dispatch, FC, SetStateAction } from "react";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { FormType, ListEntry } from "../../common";
import { DairyV2InputContinuousGrowing, DairyV2Intervention } from "../../../../models/Intervention/DairyV2Intervention";
import { useFormContext } from "react-hook-form";
import { InternalSource } from "../../../../models/Intervention/CattleIntervention";
import { StageType } from "../../../../../../graphql/types";
import ReactHookDsmSelect from "../../../../../modules/Helpers/ReactHookDsmSelect2";
import { capitalize } from "lodash";
import { AnimalType } from "../../../../../../graphql/generated/blonk/cattle";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import ReactHookDsmRadioGroup from "../../../../../modules/Helpers/ReactHookDsmRadioGroup";
import { Tooltip } from "@material-ui/core";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { unitLong } from "../../../../utils/unit-utils";
import { Spacer } from "../../../helpers/Spacer";

interface DairyV2InputInternalSourceComponentProps {
  formType: FormType;
  stageType: StageType;
  productionProcessName: string;
  fieldItemPrefix: string;
  defaultStageData: DairyV2InputContinuousGrowing;
  isInternalEdit: boolean;
  setIsInternalEdit: Dispatch<SetStateAction<boolean>>;
}

const DairyV2InputInternalSourceComponent: FC<DairyV2InputInternalSourceComponentProps> = ({
  formType,
  stageType,
  productionProcessName,
  fieldItemPrefix,
  defaultStageData,
  isInternalEdit,
  setIsInternalEdit,
}) => {
  const intl = useIntl();
  const classes: CSSClassesList = processAndStageStylesV2();
  const fc = useFormContext<DairyV2Intervention>();
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const distanceUnit = unitLong(
    userUOM.unitTransportDistanceTerrestrial as string
  );

  const animalTypeOptions = () => Object.entries(AnimalType).map(([label, value]) => ({ label, value }));
  const formatStageType = (stageType: string): string => capitalize(stageType.toLowerCase().replace('_', ' '));

  const defaultFarm = (index: number): ListEntry[] => {
    const defaultFarm = (defaultStageData.internalSources ?? [])[index];
    return [{
      text: defaultFarm.farmName,
      value: defaultFarm.farmId
    }]
  };

  const defaultStage = (index: number): ListEntry[] => {
    const defaultStage = (defaultStageData.internalSources ?? [])[index];
    return [{
      text: `${productionProcessName} - ${defaultStage.stageName} (${formatStageType(defaultStage.stageType)})`,
      value: defaultStage.originStageId
    }]
  }

  const resetEdit = () => {
    (defaultStageData?.internalSources || [])
      ?.filter((item) => !!item.id)
      ?.forEach((intSource, index) => {
        fc.setValue(`${fieldItemPrefix}.internalSources[${index}].animalType`, intSource.animalType);
        fc.setValue(`${fieldItemPrefix}.internalSources[${index}].numberAnimals`, intSource.numberAnimals);
        fc.setValue(`${fieldItemPrefix}.internalSources[${index}].distanceTruck`, intSource.distanceTruck);
        fc.setValue(`${fieldItemPrefix}.internalSources[${index}].shrinkingRate`, intSource.shrinkingRate);
      });

    setIsInternalEdit(false);
  };

  const internalSourceUnit = (intSource: InternalSource, index: number) => {
    const fieldPrefix = `${fieldItemPrefix}.internalSources[${index}]`;
    const selectedStageType = intSource.stageType;
    const defFarm = defaultFarm(index);
    const defStage = defaultStage(index);

    return (
      <DsmGrid className={classes.additionalEntriesBlockHolder}>
        <ReactHookDsmSelect
          style={{ width: '100%' }}
          name={`${fieldPrefix}.farmId`}
          label={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.FARM' })}
          options={defFarm}
          defaultValue={defFarm[0]?.value}
          disabled
        />
        <Spacer />
        <ReactHookDsmSelect
          style={{ width: '100%' }}
          name={`${fieldPrefix}.originStageId`}
          label={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.STAGE` })}
          options={defStage}
          defaultValue={defStage[0]?.value}
          disabled
        />
        <Spacer />
        {stageType === StageType.ContinuousGrowing && selectedStageType === StageType.DairyCow && (
          <DsmGrid className={classes.dsmGridSmallerColumnNoRowSpacing}>
            <ReactHookDsmRadioGroup
              name={`${fieldPrefix}.animalType`}
              label={`${intl.formatMessage({ id: 'SUSTELL.STAGE.BEEF.INPUT.ANIMAL_TYPE' })}`}
              options={animalTypeOptions()}
              disabled
              defaultValue={intSource.animalType}
            />
            <Tooltip
              title={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMAL_TYPE.TOOLTIP' })}
              placement="left"
              style={{ transform: 'translateY(85%)' }}
            >
              <DsmIcon
                name="general/help-circle"
                className={classes.inlineTooltipClass}
                title=""
              />
            </Tooltip>
          </DsmGrid>
        )}
        {!isInternalEdit ? (
          <>
            {!!fc.getValues(`${fieldPrefix}.numberAnimals`) ? (
              <>
                <RowTextFieldWithMetrics
                  name={`${fieldPrefix}.numberAnimals`}
                  label={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE` })}
                  tooltip={intl.formatMessage({
                    id: stageType === StageType.ContinuousGrowing
                      ? `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.CONTINUOUS_GROWING.TOOLTIP`
                      : `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.TOOLTIP`,
                  })}
                  type="number"
                  metricUnit=""
                  baseline={intSource.numberAnimals}
                  displayTypeSelect={false}
                  disabled
                />
                <Spacer />
              </>
            ) : (
              <ReactHookDsmInput
                name={`${fieldPrefix}.originalNumberAnimals`}
                label={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE` })}
                adornment={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.ADORNMENT` })}
                tooltip={intl.formatMessage({
                  id: stageType === StageType.ContinuousGrowing
                    ? `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.CONTINUOUS_GROWING.TOOLTIP`
                    : `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.TOOLTIP`,
                })}
                type="number"
                defaultValue={intSource.numberAnimals ?? ''}
                disabled
              />
            )}
            {!!fc.getValues(`${fieldPrefix}.distanceTruck`) ? (
              <>
                <RowTextFieldWithMetrics
                  name={`${fieldPrefix}.distanceTruck`}
                  label={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK` })}
                  tooltip={intl.formatMessage({
                    id: stageType === StageType.ContinuousGrowing
                      ? `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK.CONTINUOUS_GROWING.TOOLTIP`
                      : `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK.TOOLTIP`,
                  })}
                  type="number"
                  metricUnit=""
                  baseline={intSource.distanceTruck}
                  displayTypeSelect={false}
                  disabled
                />
                <Spacer />
              </>
            ) : (
              <ReactHookDsmInput
                name={`${fieldPrefix}.originalDistanceTruck`}
                label={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK` })}
                adornment={distanceUnit}
                tooltip={intl.formatMessage({
                  id: stageType === StageType.ContinuousGrowing
                    ? `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK.CONTINUOUS_GROWING.TOOLTIP`
                    : `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK.TOOLTIP`,
                })}
                type="number"
                defaultValue={intSource.distanceTruck ?? ''}
                disabled
              />
            )}
            {!!fc.getValues(`${fieldPrefix}.shrinkingRate`) ? (
              <RowTextFieldWithMetrics
                name={`${fieldPrefix}.shrinkingRate`}
                label={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS` })}
                tooltip={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS.TOOLTIP` })}
                type="number"
                metricUnit=""
                baseline={intSource.shrinkingRate}
                displayTypeSelect={false}
                disabled
              />
            ) : (
              <ReactHookDsmInput
                name={`${fieldPrefix}.originalShrinkingRate`}
                label={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS` })}
                tooltip={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS.TOOLTIP` })}
                placeholder={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS.PLACEHOLDER` })}
                adornment="%"
                type="number"
                defaultValue={intSource.shrinkingRate ?? ''}
                disabled
              />
            )}
          </>
        ) : (
          <>
            <RowTextFieldWithMetrics
              name={`${fieldPrefix}.numberAnimals`}
              label={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE` })}
              tooltip={intl.formatMessage({
                id: stageType === StageType.ContinuousGrowing
                  ? `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.CONTINUOUS_GROWING.TOOLTIP`
                  : `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.TOOLTIP`,
              })}
              type="number"
              metricUnit=""
              baseline={intSource.numberAnimals}
              disabled={false}
            />
            <Spacer />
            <RowTextFieldWithMetrics
              name={`${fieldPrefix}.distanceTruck`}
              label={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK` })}
              tooltip={intl.formatMessage({
                id: stageType === StageType.ContinuousGrowing
                  ? `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK.CONTINUOUS_GROWING.TOOLTIP`
                  : `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK.TOOLTIP`,
              })}
              type="number"
              metricUnit=""
              baseline={intSource.distanceTruck}
              disabled={false}
            />
            <Spacer />
            <RowTextFieldWithMetrics
              name={`${fieldPrefix}.shrinkingRate`}
              label={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS` })}
              tooltip={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS.TOOLTIP` })}
              type="number"
              metricUnit=""
              baseline={intSource.shrinkingRate}
              disabled={false}
            />
          </>
        )}
      </DsmGrid>
    )
  }

  return (
    <div>
      <label htmlFor="internalSource" className={classes.headingTitleVariant}>
        {intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.INTERNAL_SOURCE' })}
      </label>
      <DsmGrid className={classes.dsmGridOneColumnInterventionWithBorder}>
        {(defaultStageData?.internalSources || [])
          ?.filter((item) => !!item.farmId)
          ?.map((item, index) => (internalSourceUnit(item, index)))}
        {!isInternalEdit ? (
          <DsmButton
            variant="text"
            onClick={() => setIsInternalEdit(true)}
            disabled={formType === FormType.View}
            style={{ width: "120px" }}
          >
            <DsmIcon slot="before" name="general/edit-02" />
            <u>
              {intl.formatMessage({ id: "INTERVENTION.FORM.EDIT_INPUT" })}
            </u>
          </DsmButton>
        ) : (
          <>
            <DsmButton
              variant="text"
              onClick={() => resetEdit()}
            >
              <DsmIcon slot="before" name="arrows/refresh-cw-01" />
              {intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.RESET_DEFAULT_VALUES' })}
            </DsmButton>
            <DsmButton
              variant="text"
              destructive
              onClick={() => setIsInternalEdit(false)}
              style={{ width: "160px" }}
            >
              <DsmIcon slot="before" name="general/minus-circle" />
              <u>
                {intl.formatMessage({ id: "GENERAL.CANCEL" })}
              </u>
            </DsmButton>
          </>
        )}
      </DsmGrid>
    </div>
  )
}

export default DairyV2InputInternalSourceComponent;
