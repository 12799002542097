import { Dispatch, FC, SetStateAction, useState } from "react";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { DairyIntervention } from "../../../../models/Intervention";
import { useFormContext } from "react-hook-form";
import { DsmButton, DsmCheckbox, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { CattleFeed } from "../../../../models/Intervention/CattleIntervention";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { unitLong } from "../../../../utils/unit-utils";
import { CheckboxChangeEvent, DsmCheckboxCustomEvent } from "@dsm-dcs/design-system";
import { Box, Tooltip } from "@material-ui/core";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { Spacer } from "../../../helpers/Spacer";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { FormType } from "../../common";

interface DairyV2FeedMothersMilkComponentProps {
  formType: FormType,
  fieldItemPrefix: string;
  currentStageData: CattleFeed;
  isMotherMilkEdit: boolean;
  setIsMotherMilkEdit: Dispatch<SetStateAction<boolean>>;
}

const DairyV2FeedMothersMilkComponent: FC<DairyV2FeedMothersMilkComponentProps> = ({
  formType,
  fieldItemPrefix,
  currentStageData,
  isMotherMilkEdit,
  setIsMotherMilkEdit
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fc = useFormContext<DairyIntervention>();
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassUnit = unitLong(
    userUOM.unitBarnOutputMass === "kg"
      ? ("kg DM" as string)
      : userUOM.unitBarnOutputMass
  );

  const defaultValue = () => currentStageData.mothersMilk?.isMothersMilk ? "true" : "false";

  const [isMotherMilk, setIsMotherMilk] = useState<boolean>(!!currentStageData.mothersMilk?.isMothersMilk);

  const updateInputDisplay = (e: DsmCheckboxCustomEvent<CheckboxChangeEvent>) => {
    const newState = e.target?.checked;
    fc.setValue(`${fieldItemPrefix}.isMothersMilk`, newState);
    setIsMotherMilk(newState);
  }

  const isCheckBoxDisabled = () => {
    if (formType === FormType.View || currentStageData.mothersMilk?.isMothersMilk || !isMotherMilkEdit)
      return true;
    return false;
  }

  const resetEdit = () => {
    fc.setValue(`${fieldItemPrefix}.isMothersMilk`, !!currentStageData.mothersMilk?.isMothersMilk);
    fc.setValue(`${fieldItemPrefix}.amount`, "");
    fc.setValue(`${fieldItemPrefix}.dryMatterContent`, "");
    fc.setValue(`${fieldItemPrefix}.crudeProteinContent`, "");
    fc.setValue(`${fieldItemPrefix}.digestibility`, "");
    setIsMotherMilkEdit(false);
  }

  return (
    <>
      <DsmGrid className={classes.dsmGridOneColumnWithBorder}>
        <div className={classes.resetButtonContainer}>
          <DsmCheckbox
            name={`${fieldItemPrefix}.isMothersMilk`}
            defaultValue={defaultValue()}
            checked={isMotherMilk}
            disabled={isCheckBoxDisabled()}
            onDsmChange={(e) => updateInputDisplay(e)}
            label={intl.formatMessage({
              id: `SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.MOTHERS_MILK_TO_CALVES`,
            })}
            style={{
              marginBottom: '16px',
            }}
          />
          <Tooltip
            title={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.MOTHERS_MILK_TO_CALVES',
            })}
            placement="left"
            className='ml-3'
            style={{ transform: 'translateY(10%)' }}
          >
            <DsmIcon
              name="general/help-circle"
              className={classes.inlineTooltipClass}
              title=""
            />
          </Tooltip>
        </div>
        <Box />
        {isMotherMilk && !isMotherMilkEdit &&
          <DsmGrid className={classes.dsmGridFourColumnNoAlignment}>
            {!!fc.getValues(`${fieldItemPrefix}.amount`) ? (
              <div><RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.amount`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.AMOUNT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.AMOUNT.TOOLTIP',
                })}
                type="number"
                metricUnit=""
                baseline={currentStageData.mothersMilk?.amount}
                disabled
                displayTypeSelect={false}
              />
                <Spacer />
              </div>
            ) : (
              <ReactHookDsmInput
                name={`${fieldItemPrefix}.originalAmount`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.AMOUNT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.AMOUNT.TOOLTIP',
                })}
                adornment={barnOutputMassUnit}
                type="number"
                defaultValue={currentStageData.mothersMilk?.amount ?? ''}
                disabled
              />
            )}
            {!!fc.getValues(`${fieldItemPrefix}.dryMatterContent`) ? (
              <div>
                <RowTextFieldWithMetrics
                  name={`${fieldItemPrefix}.dryMatterContent`}
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT',
                  })}
                  tooltip={intl.formatMessage({
                    id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT.TOOLTIP',
                  })}
                  type="number"
                  metricUnit=""
                  baseline={currentStageData.mothersMilk?.dryMatterContent}
                  disabled
                  displayTypeSelect={false}
                />
                <Spacer />
              </div>
            ) : (
              <ReactHookDsmInput
                name={`${fieldItemPrefix}.originalDryMatterContent`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT.TOOLTIP',
                })}
                adornment={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT.ADORNMENT',
                })}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT.PLACEHOLDER',
                })}
                type="number"
                defaultValue={currentStageData.mothersMilk?.dryMatterContent ?? ''}
                disabled
              />
            )}
            {!!fc.getValues(`${fieldItemPrefix}.crudeProteinContent`) ? (
              <div>
                <RowTextFieldWithMetrics
                  name={`${fieldItemPrefix}.crudeProteinContent`}
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT',
                  })}
                  tooltip={intl.formatMessage({
                    id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT.TOOLTIP',
                  })}
                  type="number"
                  metricUnit=""
                  baseline={currentStageData.mothersMilk?.crudeProteinContent}
                  disabled
                  displayTypeSelect={false}
                />
                <Spacer />
              </div>
            ) : (
              <ReactHookDsmInput
                name={`${fieldItemPrefix}.originalCrudeProteinContent`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT.TOOLTIP',
                })}
                adornment={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT.ADORNMENT',
                })}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT.PLACEHOLDER',
                })}
                type="number"
                defaultValue={currentStageData.mothersMilk?.crudeProteinContent ?? ''}
                disabled
              />
            )}
            {!!fc.getValues(`${fieldItemPrefix}.digestibility`) ? (
              <div>
                <RowTextFieldWithMetrics
                  name={`${fieldItemPrefix}.digestibility`}
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DIGESTIBILITY',
                  })}
                  tooltip={intl.formatMessage({
                    id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DIGESTIBILITY.TOOLTIP',
                  })}
                  type="number"
                  metricUnit=""
                  baseline={currentStageData.mothersMilk?.digestibility}
                  disabled
                  displayTypeSelect={false}
                />
                <Spacer />
              </div>
            ) : (
              <ReactHookDsmInput
                name={`${fieldItemPrefix}.originalDigestibility`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DIGESTIBILITY',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DIGESTIBILITY.TOOLTIP',
                })}
                adornment={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DIGESTIBILITY.ADORNMENT',
                })}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DIGESTIBILITY.PLACEHOLDER',
                })}
                type="number"
                defaultValue={currentStageData.mothersMilk?.digestibility ?? ''}
                disabled
              />
            )}
          </DsmGrid>}
        {isMotherMilk && isMotherMilkEdit &&
          <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.amount`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.AMOUNT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.AMOUNT.TOOLTIP',
                })}
                metricUnit=""
                type="number"
                baseline={currentStageData.mothersMilk?.amount}
                disabled={false}
              />
              <Spacer />
            </div>
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.dryMatterContent`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DRY_MATTER_CONTENT.TOOLTIP',
                })}
                metricUnit=""
                type="number"
                baseline={currentStageData.mothersMilk?.dryMatterContent}
                disabled={false}
              />
              <Spacer />
            </div>
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.crudeProteinContent`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.CRUDE_PROTEIN_CONTENT.TOOLTIP',
                })}
                metricUnit=""
                type="number"
                baseline={currentStageData.mothersMilk?.crudeProteinContent}
                disabled={false}
              />
              <Spacer />
            </div>
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.digestibility`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DIGESTIBILITY',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.FEED.MOTHERS_MILK.DIGESTIBILITY.TOOLTIP',
                })}
                metricUnit=""
                type="number"
                baseline={currentStageData.mothersMilk?.digestibility}
                disabled={false}
              />
              <Spacer />
            </div>
          </DsmGrid>}
        {!isMotherMilkEdit ? (
          <DsmButton
            variant="text"
            onClick={() => setIsMotherMilkEdit(true)}
            style={{ width: "120px" }}
            disabled={formType === FormType.View}
          >
            <DsmIcon slot="before" name="general/edit-02" />
            <u>
              {intl.formatMessage({
                id: "INTERVENTION.FORM.EDIT_INPUT",
              })}
            </u>
          </DsmButton>
        ) : (
          <DsmButton
            variant="text"
            destructive
            onClick={() => resetEdit()}
            style={{ width: "160px" }}
            disabled={formType === FormType.View}
          >
            <DsmIcon slot="before" name="general/minus-circle" />
            <u>
              {intl.formatMessage({
                id: "GENERAL.CANCEL",
              })}
            </u>
          </DsmButton>
        )}
      </DsmGrid>
    </>
  )
}

export default DairyV2FeedMothersMilkComponent;
