import { Grid, Typography } from "@material-ui/core";
import { FootprintCategoryBox } from "./FootprintCategoryBox";
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import useFootprintFilters from "./useFootprintFilters";

interface FootprintHeaderProps {
  categoryName: string;
  categoryValue: number;
  categoryConversationText: string;
  categoryUnitDescription: string;
  categoryDescription: string;
  footprintType: string;
  // eslint-disable-next-line react/require-default-props
  databaseName?: string;
  animalType?: string;
  numberOfImpacts: number;
}

const FootprintHeader = ({
  categoryName,
  categoryValue,
  categoryConversationText,
  categoryUnitDescription,
  categoryDescription,
  footprintType,
  databaseName,
  animalType,
  numberOfImpacts,
}: FootprintHeaderProps) => {
  const intl = useIntl();
  const descriptionText = (): string => {
    if (footprintType === "f")
      return intl.formatMessage({ id: "SUSTELL.FOOTPRINT.DESCRIPTION.FEED" });
    switch (animalType) {
      case "SALMON_V2":
      case "SEA_BASS":
      case "SEA_BREAM":
        return intl.formatMessage(
          {
            id: "SUSTELL.FOOTPRINT.DESCRIPTION.FARM.SALMON",
          },
          { impacts: numberOfImpacts }
        );
      default:
        return intl.formatMessage(
          { id: "SUSTELL.FOOTPRINT.DESCRIPTION.FARM" },
          { impacts: numberOfImpacts }
        );
    }
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={8}>
        <Typography variant="h6" color="textPrimary">
          {intl.formatMessage({ id: "SUSTELL.FOOTPRINT.TITLE" })}
        </Typography>
        <Typography
          color="inherit"
          style={{ marginTop: "10px" }}
          variant="body1"
        >
          {descriptionText()}
        </Typography>
        {footprintType === "f" && databaseName && (
          <Typography
            color="inherit"
            style={{ marginTop: "10px" }}
            variant="body1"
          >
            <b>
              {" "}
              {intl.formatMessage({
                id: `SUSTELL.FOOTPRINT.FEED.DATABASE`,
              })}
              : {databaseName}
            </b>{" "}
          </Typography>
        )}
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={3}>
        <FootprintCategoryBox
          categoryName={categoryName}
          categoryValue={categoryValue}
          categoryUnitDescription={categoryUnitDescription}
          categoryConversationText={categoryConversationText}
          categoryDescription={categoryDescription}
        />
      </Grid>
    </Grid>
  );
};

export default FootprintHeader;
