import { Dispatch, FC, SetStateAction, useState } from "react";
import { FormType } from "../../common";
import { DairyV2OutputDairyCow } from "../../../../models/Baseline/DairyV2Baseline";
import { useFormContext } from "react-hook-form";
import { DairyIntervention } from "../../../../models/Intervention";
import { DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { Button } from "@material-ui/core";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { Spacer } from "../../../helpers/Spacer";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { unitLong } from "../../../../utils/unit-utils";

interface DairyV2OutputCowCowsComponentProps {
  formType: FormType,
  fieldItemPrefix: string,
  currentStageData: DairyV2OutputDairyCow,
  isCowsEdit: boolean,
  setIsCowsEdit: Dispatch<SetStateAction<boolean>>
}

const DairyV2OutputCowCowsComponent: FC<DairyV2OutputCowCowsComponentProps> = ({
  formType = FormType.Add,
  fieldItemPrefix,
  currentStageData,
  isCowsEdit,
  setIsCowsEdit,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fc = useFormContext<DairyIntervention>();

  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);

  const resetEdit = () => {
    fc.setValue(`${fieldItemPrefix}.cowsToAnotherStage`, "");
    fc.setValue(`${fieldItemPrefix}.averageWeightOfCowsLeavingTheStage`, "");
    fc.setValue(`${fieldItemPrefix}.cowsSold`, "");
    fc.setValue(`${fieldItemPrefix}.numberOfMortalitiesCows`, "");
    fc.setValue(`${fieldItemPrefix}.averageWeightAtMortalityCows`, "");
    setIsCowsEdit(false);
  };

  return (
    <DsmGrid className={classes.dsmGridOneColumnWithBorder}>
      {!isCowsEdit ? (
        <>
          {!!fc.getValues(`${fieldItemPrefix}.cowsToAnotherStage`) ?
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.cowsToAnotherStage`}
                label={intl.formatMessage({
                  id: "SUSTELL.STAGE.DAIRY.OUTPUT.COWS_TO_ANOTHER_STAGE"
                })}
                tooltip={intl.formatMessage({
                  id: "SUSTELL.STAGE.DAIRY.OUTPUT.COWS_TO_ANOTHER_STAGE.TOOLTIP"
                })}
                type="number"
                metricUnit=""
                baseline={currentStageData?.cowsToAnotherStage}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div> :
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalCowsToAnotherStage`}
              label={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.COWS_TO_ANOTHER_STAGE"
              })}
              tooltip={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.COWS_TO_ANOTHER_STAGE.TOOLTIP"
              })}
              adornment={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.COWS_TO_ANOTHER_STAGE.PLACEHOLDER"
              })}
              type="number"
              defaultValue={currentStageData.cowsToAnotherStage}
              disabled
            />
          }
          {!!fc.getValues(`${fieldItemPrefix}.averageWeightOfCowsLeavingTheStage`) ?
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.averageWeightOfCowsLeavingTheStage`}
                label={intl.formatMessage({
                  id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE"
                })}
                tooltip={intl.formatMessage({
                  id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE.TOOLTIP"
                })}
                type="number"
                metricUnit=""
                baseline={currentStageData?.averageWeightOfCowsLeavingTheStage}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div> :
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAverageWeightOfCowsLeavingTheStage`}
              label={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE"
              })}
              tooltip={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE.TOOLTIP"
              })}
              adornment={intl.formatMessage(
                {
                  id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE.PLACEHOLDER"
                },
                {
                  unit: barnOutputMassUnit
                }
              )}
              type="number"
              defaultValue={currentStageData.averageWeightOfCowsLeavingTheStage}
              disabled
            />
          }
          {!!fc.getValues(`${fieldItemPrefix}.cowsSold`) ?
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.cowsSold`}
                label={intl.formatMessage({
                  id: "SUSTELL.STAGE.DAIRY.OUTPUT.COWS_SOLD"
                })}
                type="number"
                metricUnit=""
                baseline={currentStageData?.cowsSold}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div> :
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalCowsSold`}
              label={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.COWS_SOLD"
              })}
              adornment={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.COWS_SOLD.PLACEHOLDER"
              })}
              type="number"
              defaultValue={currentStageData.cowsSold}
              disabled
            />
          }
          {!!fc.getValues(`${fieldItemPrefix}.numberOfMortalitiesCows`) ?
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.numberOfMortalitiesCows`}
                label={intl.formatMessage({
                  id: "SUSTELL.STAGE.DAIRY.OUTPUT.NUMBER_OF_MORTALITIES_COWS"
                })}
                type="number"
                metricUnit=""
                baseline={currentStageData?.numberOfMortalitiesCows}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div> :
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalNumberOfMortalitiesCows`}
              label={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.NUMBER_OF_MORTALITIES_COWS"
              })}
              adornment={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.NUMBER_OF_MORTALITIES_COWS.PLACEHOLDER"
              })}
              type="number"
              defaultValue={currentStageData.numberOfMortalitiesCows}
              disabled
            />
          }
          {!!fc.getValues(`${fieldItemPrefix}.averageWeightAtMortalityCows`) ?
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.averageWeightAtMortalityCows`}
                label={intl.formatMessage({
                  id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_AT_MORTALITY_COWS"
                })}
                type="number"
                metricUnit=""
                baseline={currentStageData?.averageWeightAtMortalityCows}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div> :
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAverageWeightAtMortalityCows`}
              label={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_AT_MORTALITY_COWS"
              })}
              adornment={intl.formatMessage(
                {
                  id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_AT_MORTALITY_COWS.PLACEHOLDER"
                },
                {
                  unit: barnOutputMassUnit
                }
              )}
              type="number"
              defaultValue={currentStageData.averageWeightAtMortalityCows}
              disabled
            />
          }

          <div id='button-div'
            style={{
              color: 'var(--dsm-color-neutral-darker',
              left: '0',
              display: 'flex',
              flexDirection: 'column'
            }}>

            <Button
              href=""
              style={{
                textDecoration: 'underline',
                color: '#0070BA',
                display: (!isCowsEdit && formType !== 'view') ? 'flex' : 'none',
                placeSelf: 'start',
                textTransform: 'none',
              }}
              onClick={() => setIsCowsEdit(true)}
              disabled={formType === 'view'}
            >
              <DsmIcon
                slot="before"
                name="editor/pencil-01"
                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
              />
              {intl.formatMessage({ id: 'GENERAL.EDIT_INPUT' })}
            </Button>
          </div>
        </>
      ) : (
        <>
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.cowsToAnotherStage`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.DAIRY.OUTPUT.COWS_TO_ANOTHER_STAGE"
            })}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.DAIRY.OUTPUT.COWS_TO_ANOTHER_STAGE.TOOLTIP"
            })}
            type="number"
            metricUnit=""
            baseline={currentStageData.cowsToAnotherStage}
            disabled={false}
          />
          <Spacer />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.averageWeightOfCowsLeavingTheStage`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE"
            })}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE.TOOLTIP"
            })}
            type="number"
            metricUnit=""
            baseline={currentStageData.averageWeightOfCowsLeavingTheStage}
            disabled={false}
          />
          <Spacer />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.cowsSold`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.DAIRY.OUTPUT.COWS_SOLD"
            })}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.DAIRY.OUTPUT.COWS_SOLD.TOOLTIP"
            })}
            type="number"
            metricUnit=""
            baseline={currentStageData.cowsSold}
            disabled={false}
          />
          <Spacer />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.numberOfMortalitiesCows`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.DAIRY.OUTPUT.NUMBER_OF_MORTALITIES_COWS"
            })}
            type="number"
            metricUnit=""
            baseline={currentStageData.numberOfMortalitiesCows}
            disabled={false}
          />
          <Spacer />
          <RowTextFieldWithMetrics
            name={`${fieldItemPrefix}.averageWeightAtMortalityCows`}
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_AT_MORTALITY_COWS"
            })}
            type="number"
            metricUnit=""
            baseline={currentStageData.averageWeightAtMortalityCows}
            disabled={false}
          />
          {isCowsEdit === true && (
            <Button
              href=""
              style={{
                textDecoration: 'underline',
                color: '#FF0000',
                placeSelf: 'start',
                textTransform: 'none',
              }}
              onClick={() => resetEdit()}
              disabled={formType === 'view'}
            >
              <DsmIcon
                slot="before"
                name="general/minus-circle"
                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
              />
              {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
            </Button>
          )}
        </>
      )}
    </DsmGrid>
  )
}

export default DairyV2OutputCowCowsComponent;
