import { FC, useEffect, useRef, useState } from "react";
import { BaselineDialogProps, FormType } from "../../common";
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { DairyIntervention, DairyOutput } from "../../../../models/Intervention";
import { useFormContext } from "react-hook-form";
import { DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { DairyV2Baseline, DairyV2ContinuousGrowingStageData } from "../../../../models/Baseline/DairyV2Baseline";
import { Button } from "@material-ui/core";
import DairyV2OutputContinuousGrowingForm from "./DairyV2OutputContinuousGrowingForm";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";
import { cloneDeep } from "lodash";

interface DairyV2OutputContinuousGrowingDialogProps extends BaselineDialogProps {
  formType: FormType;
  formVisible: boolean;
  stageIndex: number;
  baseline: DairyV2Baseline;
  handleCancel: (param?: string | null) => void;
}

const DairyV2OutputContinuousGrowingDialog: FC<DairyV2OutputContinuousGrowingDialogProps> = ({
  formType = FormType.Add,
  formVisible,
  stageIndex = 0,
  baseline,
  handleCancel,
}) => {
  const intl = useIntl();
  const classes: CSSClassesList = processAndStageStylesV2();
  const formTitle = intl.formatMessage({ id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE', });

  const fieldItemPrefix = `stages.${stageIndex}.stageData.output`;

  const currResetValue = useRef<DairyOutput>();
  const fc = useFormContext<DairyIntervention>();

  const currentStageData = baseline.stages[stageIndex].stageData;
  const dairyV2ContinuousGrowingOutputData = (currentStageData as DairyV2ContinuousGrowingStageData).output;

  const [isOutputEdit, setisOutputEdit] = useState<boolean>(false);

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
    }
  }, [formVisible]);

  const handleDialogCancelClick = () => {
    if (currResetValue.current) {
      for (let [propertyName, key] of Object.entries(currResetValue.current)) {
        fc.setValue(`${fieldItemPrefix}.${propertyName}`, key);
      }
    }
    if (handleCancel) {
      handleCancel();
    }
  };

  const resetEdit = () => {
    for (let [propertyName] of Object.entries(dairyV2ContinuousGrowingOutputData)) {
      fc.setValue(`${fieldItemPrefix}.${propertyName}`, "");
    }
    setisOutputEdit(false);
  };

  return (
    <DialogContainer
      formVisible={true}
      handleClose={handleDialogCancelClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      datasetType="intervention"
      width={isOutputEdit ? "592px" : "460px"}
    >
      <DsmGrid className={classes.dsmGridOneColumnWithBorder}>
        {dairyV2ContinuousGrowingOutputData &&
          (
            <>
              <DairyV2OutputContinuousGrowingForm
                editEnabled={isOutputEdit}
                stageIndex={stageIndex}
                baseline={baseline}
              />
            </>
          )
        }

        <div id='button-div'
          style={{
            color: 'var(--dsm-color-neutral-darker',
            left: '0',
            display: 'flex',
            flexDirection: 'column'
          }}>

          <Button
            href=""
            style={{
              textDecoration: 'underline',
              color: '#0070BA',
              display: (!isOutputEdit && formType !== 'view') ? 'flex' : 'none',
              placeSelf: 'start',
              textTransform: 'none',
            }}
            onClick={() => setisOutputEdit(true)}
            disabled={(formType === 'view' || isOutputEdit === true)}
          >
            <DsmIcon
              slot="before"
              name="editor/pencil-01"
              style={{ paddingRight: '5px', height: '24px', width: '24px' }}
            />
            {intl.formatMessage({ id: 'GENERAL.EDIT_INPUT' })}
          </Button>

          {isOutputEdit === true && (
            <Button
              href=""
              style={{
                textDecoration: 'underline',
                color: '#FF0000',
                placeSelf: 'start',
                textTransform: 'none',
              }}
              onClick={() => resetEdit()}
              disabled={formType === 'view'}
            >
              <DsmIcon
                slot="before"
                name="general/minus-circle"
                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
              />
              {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
            </Button>
          )}
        </div>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleDialogCancelClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  )
};

export default DairyV2OutputContinuousGrowingDialog;
