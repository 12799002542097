import { FC, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { v4 as uuidv4 } from "uuid";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { FormType } from "../../common";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { enumToOptionsArrayWithTranslatedStrings } from "../../../../utils/obj-utils";
import { unitLong } from "../../../../utils/unit-utils";
import RowControlledTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { BeddingType } from "../../../../../../graphql/generated/blonk/cattle";
import {
    CattleManureManagementSystemType,
    StoragePeriod,
} from "../../../../models/Baseline/CattleBaseline";
import {
    DairyV2ContinuousGrowingStageData,
    DairyV2DairyCowStageData,
    DairyV2Intervention,
    DairyV2Stage
} from '../../../../models/Intervention/DairyV2Intervention';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import BeddingInputComponent from '../../CommonDataParts/BeddingInputComponent';
import { StageType } from '../../../../../../graphql/types';
import DefinitionsDialog from '../../CommonDataParts/v2.0/DefinitionsDialog';
import lookupValues from '../../../../helpers/lookupValues';
import DairyV2HousingComponent from './DairyV2HousingComponent';
import DairyV2InterventionMMSComponent from './DairyV2InterventionMMSComponent';
import SustellMMSAdditionalItem from '../../../../../modules/Helpers/SustellMMSAdditionalItem';

type MMSInput = {
    mmsType: string;
    storagePeriod: string;
    share: string;
    id: string;
};

export interface DairyV2HousingAndManureFormProps {
    formType: FormType;
    stageIndex: number;
    baseline: DairyV2Stage;
    stageType: string;
    fieldItemPrefix: string;
}

const DairyV2HousingAndManureForm: FC<DairyV2HousingAndManureFormProps> = ({
    formType = FormType.Add,
    baseline,
    stageIndex = 0,
    fieldItemPrefix,
    stageType,
}) => {
    const intl = useIntl();
    const userProfile = UserProfilePrefs.getInstance();
    const userUOM = userProfile.getUserUnitPrefs();
    const classes = processAndStageStylesV2() as CSSClassesList;
    const fc = useFormContext<DairyV2Intervention>();
    const barnOutputMassUnit = userUOM?.unitBarnOutputMass
        ? unitLong(userUOM.unitBarnOutputMass)
        : "kg";
    const dairyStageData = baseline.stageData as
        | DairyV2ContinuousGrowingStageData
        | DairyV2DairyCowStageData;
    const [isBeddingEdit, setIsBeddingEdit] = useState<boolean>(false);
    const [isMMSEdit, setIsMMSEdit] = useState<boolean>(false);
    const [isDescriptionDialogVisible, setIsDescriptionDialogVisible] = useState(false);

    const getBeddingOptions = () => {
        const beddings = enumToOptionsArrayWithTranslatedStrings(
            BeddingType,
            intl,
            "SUSTELL.DAIRY.ENUMS.BEDDING_TYPE"
        );
        const defaultOption = { text: "No bedding", value: "" };
        return [defaultOption].concat(beddings);
    };
    const getMMSTypeOptions = () =>
        enumToOptionsArrayWithTranslatedStrings(
            CattleManureManagementSystemType,
            intl,
            "SUSTELL.DAIRY.ENUMS.MMS_TYPE"
        );
    const getMMSStorageOptions = () =>
        enumToOptionsArrayWithTranslatedStrings(
            StoragePeriod,
            intl,
            "SUSTELL.DAIRY.ENUMS.MMS_STORAGE"
        );
    const {
        fields: beddingAdditions,
        remove: removeBeddingAdditions,
    } = useFieldArray({
        name: `${fieldItemPrefix}.beddingSystemsAdditions`,
        control: fc.control,
        keyName: "keyId",
    });
    const {
        fields: mmsAdditions,
        append: appendMMSAdditions,
        remove: removeMMSAdditions,
    } = useFieldArray({
        name: `${fieldItemPrefix}.manureSystemsAdditions`,
        control: fc.control,
        keyName: "keyId",
    });
    const getSelectedMms = () => {
        const selectedMms: Array<MMSInput> =
            fc.getValues(fieldItemPrefix) || [];
        return selectedMms;
    };
    const isSingleMms = () => {
        const selectedMms = getSelectedMms();
        return selectedMms.length === 1;
    };
    const isMmsEmpty = () => {
        const selectedMms = getSelectedMms();
        return selectedMms.length === 0;
    };
    const setShareValue = (value = "100") =>
        fc.setValue(`${fieldItemPrefix}.manureSystems.${0}.share`, value);
    const addMMS = () => {
        const emptyMMS: MMSInput = {
            id: uuidv4(),
            mmsType: "",
            storagePeriod: "",
            share: isMmsEmpty() ? "100" : "",
        };
        if (isSingleMms()) {
            setShareValue("");
        }
        appendMMSAdditions(emptyMMS);
    };
    const cancelBeddingEdit = () => {
        (dairyStageData?.housing?.beddingSystems || [])
            ?.filter((item) => !!item.beddingType)
            ?.forEach((_, index) => {
                fc.setValue(
                    `${fieldItemPrefix}.beddingSystems[${index}].beddingAmount`,
                    ""
                );
            });
        beddingAdditions?.forEach((_, index) => {
            removeBeddingAdditions(index);
        });
        setIsBeddingEdit(false);
    };

    const cancelMMSEdit = () => {
        fc.setValue(`${fieldItemPrefix}.timeInHousing`, "");
        fc.setValue(`${fieldItemPrefix}.timeInFeedlot`, "");
        fc.setValue(`${fieldItemPrefix}.timeGrazingLargeAreas`, "");
        fc.setValue(`${fieldItemPrefix}.timeGrazingPastures`, "");
        fc.setValue(`${fieldItemPrefix}.timeInHousingTied`, "");

        (dairyStageData?.housing?.manureSystems || [])
            ?.filter((item) => !!item.mmsType)
            ?.forEach((_, index) => {
                fc.setValue(
                    `${fieldItemPrefix}.manureSystems[${index}].mmsType`,
                    ""
                );
                fc.setValue(
                    `${fieldItemPrefix}.manureSystems[${index}].storagePeriod`,
                    ""
                );
                fc.setValue(
                    `${fieldItemPrefix}.manureSystems[${index}].share`,
                    ""
                );
            });
        mmsAdditions?.forEach((_, index) => {
            removeMMSAdditions(index);
        });
        setIsMMSEdit(false);
    };
    const beefStyles = {
        container: { paddingTop: '0', width: '49%' },
        inputsContainer: { height: '60px' },
    };
    const componentStyle = beefStyles;
    const closeDescriptionDialog = () => setIsDescriptionDialogVisible(false);
    const openDescriptionDialog = () => setIsDescriptionDialogVisible(true);

    return (
        <div style={{ paddingTop: 0 }}>
            {isDescriptionDialogVisible && (
                <DefinitionsDialog
                    open
                    prefix="BASELINE.FORM.INPUT.CATTLE.MMS"
                    itemHeader="BASELINE.FORM.BARN.MANURE_MANAGEMENT_SYSTEM"
                    items={lookupValues.cattleMMSDefinitions}
                    handleClose={() => closeDescriptionDialog()}
                />
            )}
            <p className={classes.headingTitleVariant}>
                {intl.formatMessage({
                    id: "SUSTELL.STAGE.POULTRY.HOUSING.BEDDING_TYPE",
                })}
            </p>
            <DsmGrid
                className={classes.dsmGridOneColumnWithBorder}
                style={{ padding: 'var(--dsm-spacing-px-4)' }}
            >
                {(dairyStageData.housing.beddingSystems || [])
                    ?.filter((item) => !!item.beddingType)
                    ?.map((beddingItem, i: number) =>
                        !isBeddingEdit &&
                            !fc.getValues(
                                `${fieldItemPrefix}.beddingSystems[${i}].beddingAmount`
                            ) ? (
                            <DsmGrid className={classes.dsmGridThreeColumnTwoEqualLastSmall}>
                                <ReactHookDsmInput
                                    key={`${fieldItemPrefix}.beddingSystems[${i}].originalBeddingAmount`}
                                    defaultValue={beddingItem.beddingAmount as string}
                                    name={`${fieldItemPrefix}.beddingSystems[${i}].originalBeddingAmount`}
                                    label={beddingItem.beddingType?.replace('_', ' ') as string}
                                    adornment={barnOutputMassUnit}
                                    disabled={true}
                                    type="number"
                                />
                            </DsmGrid>
                        ) : (
                            <DsmGrid className={classes.dsmGridThreeColumnTwoEqualLastSmall}
                                key={`${fieldItemPrefix}.beddingSystems[${i}].beddingType`}
                                style={{
                                    marginTop: i === 0 ? '0px' : '5px',
                                    marginBottom: '16px',
                                    ...componentStyle.inputsContainer,
                                }}>
                                <input
                                    type="hidden"
                                    value={beddingItem.beddingType}
                                    ref={fc.register()}
                                    name={`${fieldItemPrefix}.beddingSystems[${i}].beddingType`}
                                />
                                <RowControlledTextFieldWithMetrics
                                    name={`${fieldItemPrefix}.beddingSystems[${i}].beddingAmount`}
                                    label={beddingItem.beddingType?.replace('_', ' ') as string}
                                    tooltip={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.BEDDING.AMOUNT.TOOLTIP' })}
                                    metricUnit={barnOutputMassUnit}
                                    baseline={beddingItem.beddingAmount}
                                    disabled={formType === "view" || !isBeddingEdit}
                                    minAllowedValue={0}
                                    originalMetricUnit={barnOutputMassUnit}
                                    displayTypeSelect={isBeddingEdit}
                                />
                            </DsmGrid>
                        )
                    )}
                {(isBeddingEdit ||
                    (!isBeddingEdit && beddingAdditions?.length !== 0)) && (
                        <div
                            style={{
                                marginTop: '15px',
                            }}
                        >
                            <BeddingInputComponent
                                stageIndex={stageIndex}
                                beddingOptions={getBeddingOptions()}
                                isViewMode={formType === FormType.View || !isBeddingEdit}
                                beddingAmoutWeightUnit={barnOutputMassUnit}
                                beddingAmountTooltipId="SUSTELL.STAGE.DAIRY.BEDDING.AMOUNT.TOOLTIP"
                                isIntervention={true}
                            />
                        </div>
                    )}

                <div style={{ paddingTop: 0 }}>
                    {!isBeddingEdit ? (
                        <DsmButton
                            variant="text"
                            onClick={() => setIsBeddingEdit(true)}
                            disabled={formType === "view"}
                            style={{ width: "120px" }}
                        >
                            <DsmIcon slot="before" name="general/edit-02" />
                            <u>
                                {intl.formatMessage({
                                    id: "INTERVENTION.FORM.EDIT_INPUT",
                                })}
                            </u>
                        </DsmButton>
                    ) : (
                        <DsmButton
                            variant="text"
                            destructive
                            onClick={() => cancelBeddingEdit()}
                            disabled={formType === "view"}
                            style={{ width: "160px" }}
                        >
                            <DsmIcon slot="before" name="general/minus-circle" />
                            <u>
                                {intl.formatMessage({
                                    id: "GENERAL.CANCEL",
                                })}
                            </u>
                        </DsmButton>
                    )}
                </div>
            </DsmGrid>

            <p className={classes.headingTitleVariant} style={{ marginTop: 'var(--dsm-spacing-px-4)' }}>
                {intl.formatMessage({
                    id: "SUSTELL.STAGE.DAIRY.MMS.DESCRIPTION",
                })}
            </p>
            <DsmGrid className={!isMMSEdit ? classes.dsmGridTwoColumn2nd45NoGap : classes.dsmGridTwoColumn2nd70NoGap}>
                <DsmGrid className={classes.dsmGridOneColumnWithBorder} style={{ padding: 0, gap: 0 }}>
                    <DairyV2HousingComponent
                        stageIndex={stageIndex}
                        isViewMode={formType === FormType.View || !isMMSEdit}
                        isDairyCowStage={stageType === StageType.DairyCow}
                        formType={formType}
                        baseline={dairyStageData.housing}
                        fieldItemPrefix={fieldItemPrefix}
                    />

                    {!isMMSEdit ? (
                        <DsmButton
                            variant="text"
                            onClick={() => setIsMMSEdit(true)}
                            disabled={formType === "view"}
                            style={{ padding: 'var(--dsm-spacing-px-4)', paddingTop: 0, width: "160px" }}
                        >
                            <DsmIcon slot="before" name="general/edit-02" />
                            <u>
                                {intl.formatMessage({
                                    id: "INTERVENTION.FORM.EDIT_INPUT",
                                })}
                            </u>
                        </DsmButton>
                    ) : (
                        <DsmButton
                            variant="text"
                            destructive
                            onClick={() => cancelMMSEdit()}
                            disabled={formType === "view"}
                            style={{ padding: 'var(--dsm-spacing-px-4)', paddingTop: 0 }}
                        >
                            <DsmIcon slot="before" name="general/minus-circle" />
                            <u>
                                {intl.formatMessage({
                                    id: "GENERAL.CANCEL",
                                })}
                            </u>
                        </DsmButton>
                    )}
                </DsmGrid>
                <div className={classes.dsmGridOneColumnLightBlueBoxContainer}>
                    <DairyV2InterventionMMSComponent
                        formType={formType}
                        stageIndex={stageIndex}
                        mmsOptions={getMMSTypeOptions()}
                        storageOptions={getMMSStorageOptions()}
                        openDescriptionDialog={openDescriptionDialog}
                        isViewMode={formType === FormType.View || !isMMSEdit}
                        isDairyCowStage={stageType === StageType.DairyCow}
                        fieldItemPrefix={`${fieldItemPrefix}.manureSystems`}
                        baseline={dairyStageData.housing}
                    />
                    {(isMMSEdit ||
                        (!isMMSEdit && mmsAdditions?.length > 0)) && (
                            <>
                                {mmsAdditions?.map((item, index) => (
                                    <div key={item.keyId}>
                                        <div
                                            className={classes.dsmGridTwoColumn}
                                            style={{
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <SustellMMSAdditionalItem
                                                formType={formType}
                                                stageIndex={stageIndex}
                                                index={index}
                                                mmsOptions={getMMSTypeOptions()}
                                                storageOptions={getMMSStorageOptions()}
                                                isViewMode={formType === FormType.View || !isMMSEdit}
                                                fieldItemPrefix={`${fieldItemPrefix}.manureSystemsAdditions`}
                                                isDairyCowStage={stageType === StageType.DairyCow}
                                                handleRemove={removeMMSAdditions}
                                                isIntervention={true}
                                            />
                                        </div>
                                    </div>
                                ))}
                                <DsmGrid
                                    className={classes.dsmGridTwoColumnAndIconBtn}
                                    style={{
                                        marginBottom: "var(--dsm-spacing-px-4",
                                    }}
                                >
                                    <div>
                                        <DsmButton
                                            variant="text"
                                            onClick={addMMS}
                                            disabled={!isMMSEdit}
                                        >
                                            <DsmIcon slot="before" name="general/plus-circle" />
                                            {intl.formatMessage({
                                                id: "SUSTELL.STAGE.POULTRY.HOUSING.ADD_ANOTHER_MMS",
                                            })}
                                        </DsmButton>

                                        <DsmButton
                                            variant="text"
                                            onClick={openDescriptionDialog}
                                            style={{ marginLeft: "20px" }}
                                        >
                                            <DsmIcon slot="before" name="general/eye" />
                                            {intl.formatMessage({ id: "DATABASE_FOUNDATION_DEFINITIONS" })}
                                        </DsmButton>
                                    </div>
                                </DsmGrid>
                            </>
                        )}
                </div>
            </DsmGrid>
        </div>
    );
};

export default DairyV2HousingAndManureForm;
