import { FC, useEffect, useRef, useState } from "react";
import { BaselineDialogProps, FormType } from "../../common";
import { DairyV2Baseline } from "../../../../models/Baseline/DairyV2Baseline";
import { StageType } from "../../../../../../graphql/types";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { useFormContext } from "react-hook-form";
import { DairyV2ContinuousGrowingStageData, DairyV2DairyCowStageData, DairyV2Emissions, DairyV2Intervention } from "../../../../models/Intervention/DairyV2Intervention";
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import { DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { Button } from "@material-ui/core";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";
import DairyV2EmissionMitigationForm from "./DairyV2EmissionMitigationForm";
import { cloneDeep } from "lodash";

interface DairyV2EmissionMitigationDialogProps extends BaselineDialogProps {
  stageType: StageType,
  formType: FormType;
  stageIndex: number;
  baseline: DairyV2Baseline;
  handleCancel: (param?: string | null) => void;
}

const DairyV2EmissionMitigationDialog: FC<DairyV2EmissionMitigationDialogProps> = ({
  stageType,
  formType = FormType.Add,
  stageIndex = 0,
  baseline,
  handleCancel,
}) => {
  const intl = useIntl();
  const classes: CSSClassesList = processAndStageStylesV2();
  const formTitle = intl.formatMessage({ id: 'SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE', });
  const introText = intl.formatMessage({ id: 'EMISSIONS.INTRO', });

  const currResetValue = useRef<DairyV2Emissions>();
  const fc = useFormContext<DairyV2Intervention>();

  const [isOutputEdit, setisOutputEdit] = useState<boolean>(false);

  const fieldItemPrefix = `stages.${stageIndex}.stageData.emissions.emissionMitigations`;
  const currentStageData = baseline.stages[stageIndex].stageData;

  const getEmissionsFromStageType = (currentStageData: any) => {
    if (stageType === StageType.ContinuousGrowing)
      return (currentStageData as DairyV2ContinuousGrowingStageData).emissions?.emissionMitigations;
    return (currentStageData as DairyV2DairyCowStageData).emissions?.emissionMitigations;
  }

  const handleDialogCancelClick = () => {
    if (currResetValue.current) {
      for (let [propertyName, key] of Object.entries(currResetValue.current)) {
        fc.setValue(`${fieldItemPrefix}.${propertyName}`, key);
      }
    }
    if (handleCancel) {
      handleCancel();
    }
  };

  const dairyV2EmissionsData = getEmissionsFromStageType(currentStageData) ?? {};
  const resetEdit = () => {
    for (let [propertyName] of Object.entries(dairyV2EmissionsData)) {
      fc.setValue(`${fieldItemPrefix}.${propertyName}`, "");
    }
    setisOutputEdit(false);
  };

  useEffect(() => {
    currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
  });

  return (
    <DialogContainer
      formVisible={true}
      handleClose={handleDialogCancelClick}
      iconCode="maps-travel/globe-04"
      formTitle={formTitle}
      introText={introText}
      datasetType="intervention"
      width={isOutputEdit ? "1288px" : "936px"}
      dialogContentStyles={{
        paddingBottom: '1.25rem',
        overflowY: 'unset',
      }}
    >
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        {dairyV2EmissionsData && (
          <DairyV2EmissionMitigationForm
            editEnabled={isOutputEdit}
            fieldItemPrefix={fieldItemPrefix}
            emissions={dairyV2EmissionsData}
          />
        )}
        <div />
        <div id='button-div'
          style={{
            color: 'var(--dsm-color-neutral-darker',
            left: '0',
            display: 'flex',
            flexDirection: 'column'
          }}>

          <Button
            href=""
            style={{
              textDecoration: 'underline',
              color: '#0070BA',
              display: (!isOutputEdit && formType !== 'view') ? 'flex' : 'none',
              placeSelf: 'start',
              textTransform: 'none',
            }}
            onClick={() => setisOutputEdit(true)}
            disabled={(formType === 'view' || isOutputEdit === true)}
          >
            <DsmIcon
              slot="before"
              name="editor/pencil-01"
              style={{ paddingRight: '5px', height: '24px', width: '24px' }}
            />
            {intl.formatMessage({ id: 'GENERAL.EDIT_INPUT' })}
          </Button>

          {isOutputEdit === true && (
            <Button
              href=""
              style={{
                textDecoration: 'underline',
                color: '#FF0000',
                placeSelf: 'start',
                textTransform: 'none',
              }}
              onClick={() => resetEdit()}
              disabled={formType === 'view'}
            >
              <DsmIcon
                slot="before"
                name="general/minus-circle"
                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
              />
              {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
            </Button>
          )}
        </div>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleDialogCancelClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />

    </DialogContainer>
  );
}

export default DairyV2EmissionMitigationDialog;