import { Dispatch, FC, SetStateAction } from "react";
import { FormType } from "../../common";
import { DairyV2Input } from "../../../../models/Intervention/DairyV2Intervention";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { useFormContext } from "react-hook-form";
import { Button } from "@material-ui/core";
import { DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { Spacer } from "../../../helpers/Spacer";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { unitLong } from "../../../../utils/unit-utils";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";

interface DairyV2InputNewAnimalsCowComponentProps {
  fieldItemPrefix: string;
  formType: FormType;
  defaultStageData: DairyV2Input;
  isNewAnimalsEdit: boolean;
  setIsNewAnimalsEdit: Dispatch<SetStateAction<boolean>>
}

const DairyV2InputNewAnimalsCowComponent: FC<DairyV2InputNewAnimalsCowComponentProps> = ({
  fieldItemPrefix,
  formType,
  defaultStageData,
  isNewAnimalsEdit,
  setIsNewAnimalsEdit
}) => {
  const intl = useIntl();
  const classes: CSSClassesList = processAndStageStylesV2();
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  const fc = useFormContext<DairyV2Input>();

  const resetEdit = () => {
    fc.setValue(`${fieldItemPrefix}.averageWeightNewAnimals`, "");
    setIsNewAnimalsEdit(false);
  };

  return (
    <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
      {!isNewAnimalsEdit ? (
        <>
          {!!fc.getValues(`${fieldItemPrefix}.averageWeightNewAnimals`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.averageWeightNewAnimals`}
                label={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS' })}
                tooltip={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS.CONTINUOUS_GROWING.TOOLTIP' })}
                type="number"
                metricUnit=""
                baseline={defaultStageData.averageWeightNewAnimals}
                displayTypeSelect={false}
                disabled
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAverageWeightNewAnimals`}
              label={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS' })}
              tooltip={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS.CONTINUOUS_GROWING.TOOLTIP' })}
              adornment={intl.formatMessage(
                { id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS.ADORNMENT' },
                { unit: userUOM?.unitBarnOutputMass ? unitLong(userUOM.unitBarnOutputMass) : 'kg' }
              )}
              type="number"
              defaultValue={defaultStageData.averageWeightNewAnimals ?? ''}
              disabled
            />
          )}
          <div />

          <div id='button-div'
            style={{
              color: 'var(--dsm-color-neutral-darker',
              left: '0',
              display: 'flex',
              flexDirection: 'column'
            }}>

            <Button
              href=""
              style={{
                textDecoration: 'underline',
                color: '#0070BA',
                display: (!isNewAnimalsEdit && formType !== FormType.View) ? 'flex' : 'none',
                placeSelf: 'start',
                textTransform: 'none',
              }}
              onClick={() => setIsNewAnimalsEdit(true)}
              disabled={formType === FormType.View}
            >
              <DsmIcon
                slot="before"
                name="editor/pencil-01"
                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
              />
              {intl.formatMessage({ id: 'GENERAL.EDIT_INPUT' })}
            </Button>
            <Spacer />
          </div>
        </>
      ) : (
        <>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.averageWeightNewAnimals`}
              label={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS' })}
              tooltip={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS.CONTINUOUS_GROWING.TOOLTIP' })}
              type="number"
              metricUnit=""
              baseline={defaultStageData.averageWeightNewAnimals}
              disabled={false}
            />
            <Spacer />
          </div>
          <div />

          {isNewAnimalsEdit === true && (
            <Button
              href=""
              style={{
                textDecoration: 'underline',
                color: '#FF0000',
                placeSelf: 'start',
                textTransform: 'none',
              }}
              onClick={() => resetEdit()}
              disabled={formType === 'view'}
            >
              <DsmIcon
                slot="before"
                name="general/minus-circle"
                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
              />
              {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
            </Button>
          )}
        </>
      )}
    </DsmGrid>
  )
}

export default DairyV2InputNewAnimalsCowComponent;
