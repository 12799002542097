import { DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { useFormContext } from "react-hook-form";
import { DairyIntervention } from "../../../../models/Intervention";
import { FormType } from "../../common";
import { DairyV2OutputDairyCow } from "../../../../models/Baseline/DairyV2Baseline";
import { Button } from "@material-ui/core";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { Spacer } from "../../../helpers/Spacer";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { unitLong } from "../../../../utils/unit-utils";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";

interface DairyV2OutputCowMilkComponentProps {
  formType: FormType,
  fieldItemPrefix: string,
  currentStageData: DairyV2OutputDairyCow,
  isMilkEdit: boolean,
  setIsMilkEdit: Dispatch<SetStateAction<boolean>>
}

const DairyV2OutputCowMilkComponent: FC<DairyV2OutputCowMilkComponentProps> = ({
  formType = FormType.Add,
  fieldItemPrefix,
  currentStageData,
  isMilkEdit,
  setIsMilkEdit
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fc = useFormContext<DairyIntervention>();

  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);

  const resetEdit = () => {
    fc.setValue(`${fieldItemPrefix}.yearlyMilkProduction`, "");
    fc.setValue(`${fieldItemPrefix}.averageMilkFat`, "");
    fc.setValue(`${fieldItemPrefix}.averageMilkProtein`, "");
    setIsMilkEdit(false);
  };

  return (
    <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacingWithBorder}>
      {!isMilkEdit ? (
        <>
          {!!fc.getValues(`${fieldItemPrefix}.yearlyMilkProduction`) ?
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.yearlyMilkProduction`}
                label={intl.formatMessage({
                  id: "SUSTELL.STAGE.DAIRY.OUTPUT.YEARLY_MILK_PRODUCTION"
                })}
                tooltip={intl.formatMessage({
                  id: "SUSTELL.STAGE.DAIRY.OUTPUT.YEARLY_MILK_PRODUCTION.TOOLTIP"
                })}
                type="number"
                metricUnit=""
                baseline={currentStageData?.yearlyMilkProduction}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div> :
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalYearlyMilkProduction`}
              label={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.YEARLY_MILK_PRODUCTION"
              })}
              tooltip={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.YEARLY_MILK_PRODUCTION.TOOLTIP"
              })}
              adornment={intl.formatMessage(
                {
                  id: "SUSTELL.STAGE.DAIRY.OUTPUT.YEARLY_MILK_PRODUCTION.PLACEHOLDER"
                },
                {
                  unit: barnOutputMassUnit
                }
              )}
              type="number"
              defaultValue={currentStageData.yearlyMilkProduction}
              disabled
            />
          }
          <div />
          {!!fc.getValues(`${fieldItemPrefix}.averageMilkFat`) ?
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.averageMilkFat`}
                label={intl.formatMessage({
                  id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_FAT"
                })}
                tooltip={intl.formatMessage({
                  id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_FAT.TOOLTIP"
                })}
                type="number"
                metricUnit=""
                baseline={currentStageData?.averageMilkFat}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div> :
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAverageMilkFat`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_FAT',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_FAT.PLACEHOLDER',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_FAT.TOOLTIP',
              })}
              type="number"
              defaultValue={currentStageData.averageMilkFat}
              disabled
            />
          }
          {!!fc.getValues(`${fieldItemPrefix}.averageMilkProtein`) ?
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.averageMilkProtein`}
                label={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_PROTEIN',
                })}
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_PROTEIN.TOOLTIP',
                })}
                type="number"
                metricUnit=""
                baseline={currentStageData?.averageMilkProtein}
                disabled
                displayTypeSelect={false}
              />
              <Spacer />
            </div> :
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAverageMilkProtein`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_PROTEIN',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_PROTEIN.PLACEHOLDER',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_PROTEIN.TOOLTIP',
              })}
              type="number"
              defaultValue={currentStageData.averageMilkProtein}
              disabled
            />
          }

          <div id='button-div'
            style={{
              color: 'var(--dsm-color-neutral-darker',
              left: '0',
              display: 'flex',
              flexDirection: 'column'
            }}>

            <Button
              href=""
              style={{
                textDecoration: 'underline',
                color: '#0070BA',
                display: (!isMilkEdit && formType !== 'view') ? 'flex' : 'none',
                placeSelf: 'start',
                textTransform: 'none',
              }}
              onClick={() => setIsMilkEdit(true)}
              disabled={formType === 'view'}
            >
              <DsmIcon
                slot="before"
                name="editor/pencil-01"
                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
              />
              {intl.formatMessage({ id: 'GENERAL.EDIT_INPUT' })}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.yearlyMilkProduction`}
              label={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.YEARLY_MILK_PRODUCTION"
              })}
              tooltip={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.YEARLY_MILK_PRODUCTION.TOOLTIP"
              })}
              type="number"
              metricUnit=""
              baseline={currentStageData.yearlyMilkProduction}
              disabled={false}
            />
            <Spacer />
          </div>
          <div />
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.averageMilkFat`}
              label={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_FAT"
              })}
              tooltip={intl.formatMessage({
                id: "SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_FAT.TOOLTIP"
              })}
              type="number"
              metricUnit=""
              baseline={currentStageData.averageMilkFat}
              disabled={false}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.averageMilkProtein`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_PROTEIN',
              })}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_MILK_PROTEIN.TOOLTIP',
              })}
              type="number"
              metricUnit=""
              baseline={currentStageData.averageMilkProtein}
              disabled={false}
            />
            <Spacer />
          </div>
          {isMilkEdit === true && (
            <Button
              href=""
              style={{
                textDecoration: 'underline',
                color: '#FF0000',
                placeSelf: 'start',
                textTransform: 'none',
              }}
              onClick={() => resetEdit()}
              disabled={formType === 'view'}
            >
              <DsmIcon
                slot="before"
                name="general/minus-circle"
                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
              />
              {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
            </Button>
          )}
        </>
      )}
    </DsmGrid>
  )
}

export default DairyV2OutputCowMilkComponent;
