import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { Dispatch, FC, SetStateAction } from "react";
import { FormType } from "../../common";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { useFormContext } from "react-hook-form";
import { DairyV2InputContinuousGrowing, DairyV2Intervention } from "../../../../models/Intervention/DairyV2Intervention";
import { StageType } from "../../../../../../graphql/types";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { ExternalSource } from "../../../../models/Intervention/CattleIntervention";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { unitLong } from "../../../../utils/unit-utils";
import { Spacer } from "../../../helpers/Spacer";

interface DairyV2InputExternalSourceComponentProps {
  formType: FormType;
  stageType: StageType;
  fieldItemPrefix: string;
  defaultStageData: DairyV2InputContinuousGrowing;
  isExternalEdit: boolean;
  setIsExternalEdit: Dispatch<SetStateAction<boolean>>;
}

const DairyV2InputExternalSourceComponent: FC<DairyV2InputExternalSourceComponentProps> = ({
  formType,
  stageType,
  fieldItemPrefix,
  defaultStageData,
  isExternalEdit,
  setIsExternalEdit,
}) => {
  const intl = useIntl();
  const classes: CSSClassesList = processAndStageStylesV2();
  const fc = useFormContext<DairyV2Intervention>();

  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const distanceUnit = unitLong(userUOM.unitTransportDistanceTerrestrial as string);

  const externalSourceUnit = (item: ExternalSource, index: number) => {
    const fieldPrefix = `${fieldItemPrefix}.externalSources[${index}]`;

    return (
      <DsmGrid className={classes.additionalEntriesBlockHolder}>
        {!isExternalEdit ? (
          <>
            {!!fc.getValues(`${fieldPrefix}.numberAnimals`) ? (
              <>
                <RowTextFieldWithMetrics
                  name={`${fieldPrefix}.numberAnimals`}
                  label={intl.formatMessage({ id: "SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE" })}
                  tooltip={intl.formatMessage({
                    id: stageType === StageType.ContinuousGrowing
                      ? `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.CONTINUOUS_GROWING.TOOLTIP`
                      : `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.TOOLTIP`,
                  })}
                  type="number"
                  metricUnit=""
                  baseline={item.numberAnimals}
                  displayTypeSelect={false}
                  disabled
                />
                <Spacer />
              </>
            ) : (
              <ReactHookDsmInput
                name={`${fieldPrefix}.originalNumberAnimals`}
                label={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE` })}
                adornment={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.ADORNMENT` })}
                tooltip={intl.formatMessage({
                  id: stageType === StageType.ContinuousGrowing
                    ? `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.CONTINUOUS_GROWING.TOOLTIP`
                    : `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.TOOLTIP`,
                })}
                type="number"
                defaultValue={item.numberAnimals ?? ''}
                disabled
              />
            )}
            {!!fc.getValues(`${fieldPrefix}.distanceTruck`) ? (
              <>
                <RowTextFieldWithMetrics
                  name={`${fieldPrefix}.distanceTruck`}
                  label={intl.formatMessage({ id: "SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK" })}
                  tooltip={intl.formatMessage({
                    id: stageType === StageType.ContinuousGrowing
                      ? `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK.CONTINUOUS_GROWING.TOOLTIP`
                      : `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK.TOOLTIP`,
                  })}
                  type="number"
                  metricUnit=""
                  baseline={item.distanceTruck}
                  displayTypeSelect={false}
                  disabled
                />
                <Spacer />
              </>
            ) : (
              <ReactHookDsmInput
                name={`${fieldPrefix}.originalDistanceTruck`}
                label={intl.formatMessage({ id: "SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK" })}
                adornment={distanceUnit}
                tooltip={intl.formatMessage({
                  id: stageType === StageType.ContinuousGrowing
                    ? `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK.CONTINUOUS_GROWING.TOOLTIP`
                    : `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK.TOOLTIP`,
                })}
                type="number"
                defaultValue={item.distanceTruck ?? ''}
                disabled
              />
            )}
            {!!fc.getValues(`${fieldPrefix}.shrinkingRate`) ? (
              <RowTextFieldWithMetrics
                name={`${fieldPrefix}.shrinkingRate`}
                label={intl.formatMessage({ id: "SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS" })}
                tooltip={intl.formatMessage({ id: "SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS.TOOLTIP" })}
                type="number"
                metricUnit=""
                baseline={item.shrinkingRate}
                displayTypeSelect={false}
                disabled
              />
            ) : (
              <ReactHookDsmInput
                name={`${fieldPrefix}.originalShrinkingRate`}
                label={intl.formatMessage({ id: "SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS" })}
                tooltip={intl.formatMessage({ id: "SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS.TOOLTIP" })}
                placeholder={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS.PLACEHOLDER` })}
                adornment="%"
                type="number"
                defaultValue={item.shrinkingRate ?? ''}
                disabled
              />
            )}
          </>
        ) : (
          <>
            <RowTextFieldWithMetrics
              name={`${fieldPrefix}.numberAnimals`}
              label={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE` })}
              tooltip={intl.formatMessage({
                id: stageType === StageType.ContinuousGrowing
                  ? `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.CONTINUOUS_GROWING.TOOLTIP`
                  : `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.TOOLTIP`,
              })}
              type="number"
              metricUnit=""
              baseline={item.numberAnimals}
              disabled={false}
            />
            <Spacer />
            <RowTextFieldWithMetrics
              name={`${fieldPrefix}.distanceTruck`}
              label={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK` })}
              tooltip={intl.formatMessage({
                id: stageType === StageType.ContinuousGrowing
                  ? `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK.CONTINUOUS_GROWING.TOOLTIP`
                  : `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK.TOOLTIP`,
              })}
              type="number"
              metricUnit=""
              baseline={item.distanceTruck}
              disabled={false}
            />
            <Spacer />
            <RowTextFieldWithMetrics
              name={`${fieldPrefix}.shrinkingRate`}
              label={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS` })}
              tooltip={intl.formatMessage({ id: `SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS.TOOLTIP` })}
              type="number"
              metricUnit=""
              baseline={item.shrinkingRate}
              disabled={false}
            />
          </>
        )}
      </DsmGrid>
    )
  }

  return (
    <div>
      <label htmlFor="internalSource" className={classes.headingTitleVariant}>
        {intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.EXTERNAL_SOURCE' })}
      </label>
      <DsmGrid className={classes.dsmGridOneColumnInterventionWithBorder}>
        {(defaultStageData?.externalSources || [])
          ?.filter((item) => !!item.numberAnimals)
          ?.map((item, index) => (externalSourceUnit(item, index)))}
        {!isExternalEdit ? (
          <DsmButton
            variant="text"
            onClick={() => setIsExternalEdit(true)}
            disabled={formType === FormType.View}
            style={{ width: "120px" }}
          >
            <DsmIcon slot="before" name="general/edit-02" />
            <u>
              {intl.formatMessage({ id: "INTERVENTION.FORM.EDIT_INPUT" })}
            </u>
          </DsmButton>
        ) : (
          <DsmButton
            variant="text"
            destructive
            onClick={() => setIsExternalEdit(false)}
            style={{ width: "160px" }}
          >
            <DsmIcon slot="before" name="general/minus-circle" />
            <u>
              {intl.formatMessage({ id: "GENERAL.CANCEL" })}
            </u>
          </DsmButton>
        )}
      </DsmGrid>
    </div>
  )
}

export default DairyV2InputExternalSourceComponent;
