import { Dispatch, FC, SetStateAction } from "react";
import { CattleFeed } from "../../../../models/Intervention/CattleIntervention";
import { FormType, ListEntry } from "../../common";
import { MasterDataOption, Maybe } from "../../../../../../graphql/types";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { DairyIntervention } from "../../../../models/Intervention";
import { useFieldArray, useFormContext } from "react-hook-form";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { unitLong } from "../../../../utils/unit-utils";
import { BaselineFeedWithOrigin } from "../../../../models/Baseline";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import ReactHookDsmSelect from "../../../../../modules/Helpers/ReactHookDsmSelect2";
import SustellInterventionSingleIngredientFeedComponent from "../../../../../modules/Farms/Intervention/SustellInterventionSingleIngredientFeedComponent";
import SustellSingleIngredientFeed from "../../../../../modules/Farms/Baseline/SustellSingleIngredientFeeds_v2";
import { Origin } from "../types";

interface DairyV2FeedSingleIngredientComponentProps {
  singleIngredients: Maybe<Array<MasterDataOption>>;
  origins: Maybe<Array<Origin>>;
  formType: FormType,
  stageIndex: number,
  fieldItemPrefix: string,
  currentStageData: CattleFeed,
  isSingleIngEdit: boolean,
  setIsSingleIngEdit: Dispatch<SetStateAction<boolean>>
}

const DairyV2FeedSingleIngredientComponent: FC<DairyV2FeedSingleIngredientComponentProps> = ({
  singleIngredients,
  origins,
  formType = FormType.Add,
  stageIndex,
  fieldItemPrefix,
  currentStageData,
  isSingleIngEdit,
  setIsSingleIngEdit
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fc = useFormContext<DairyIntervention>();

  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass as string) : ("kg" as string);

  const singleIngOrigins: ListEntry[] = origins as ListEntry[];

  const {
    fields: ingredientsAdditions,
    append: appendIngredientsAdditions,
    remove: removeIngredientsAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.singleIngredientsAdditions`,
    control: fc.control,
    keyName: "keyId",
  });

  const resetEdit = () => {
    (currentStageData?.singleIngredients || [])
      ?.filter((item) => !!item.feedType)
      ?.forEach((_, index) => {
        fc.setValue(
          `${fieldItemPrefix}.singleIngredients[${index}].kgPerAnimal`,
          ""
        );
      });

    if (currentStageData.singleIngredients?.length !== undefined) {
      for (let i = currentStageData.singleIngredients?.length; i >= 0; i--) {
        removeIngredientsAdditions(i);
      }
    }
    setIsSingleIngEdit(false);
  };

  return (
    <DsmGrid className={classes.dsmGridOneColumnInterventionWithBorder}>
      {(currentStageData?.singleIngredients || [])
        ?.filter((item) => !!item.feedType && !!item.origin)
        ?.map((singleIngredientItem: BaselineFeedWithOrigin, i: number) =>
          !isSingleIngEdit &&
            !fc.getValues(
              `${fieldItemPrefix}.singleIngredients[${i}].kgPerAnimal`
            ) ? (
            <DsmGrid
              className={
                classes.dsmGridTwoColumnsIngredientInterventionComponent
              }
            >
              <ReactHookDsmInput
                key={`${fieldItemPrefix}.singleIngredients[${i}].originalKgPerAnimal`}
                defaultValue={singleIngredientItem.kgPerAnimal}
                name={`${fieldItemPrefix}.singleIngredients[${i}].originalKgPerAnimal`}
                label={singleIngredientItem.feedType}
                adornment={barnOutputMassUnit}
                disabled={true}
                type="number"
              />
              <ReactHookDsmSelect
                key={`${fieldItemPrefix}.singleIngredients[${i}].originalOrigin`}
                label={intl.formatMessage({
                  id: "SUSTELL.STAGE.FEED.SINGLE_INGREDIENT.ORIGIN",
                })}
                name={`${fieldItemPrefix}.singleIngredients[${i}].originalOrigin`}
                defaultValue={singleIngredientItem.origin}
                disabled={true}
                options={singleIngOrigins}
              />
            </DsmGrid>
          ) : (
            <SustellInterventionSingleIngredientFeedComponent
              formType={
                !isSingleIngEdit ? FormType.View : formType
              }
              singleIngredient={singleIngredientItem}
              ingredientIndex={i}
              fieldItemPrefix={fieldItemPrefix}
              availableOrigins={singleIngOrigins}
              displayTypeSelect={isSingleIngEdit}
            />
          )
        )}
      {(isSingleIngEdit ||
        (!isSingleIngEdit && ingredientsAdditions?.length !== 0)) && (
          <SustellSingleIngredientFeed
            key="singleIngredients"
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.FEED.SINGLE_INGREDIENT",
            })}
            barnItemIndex={stageIndex}
            availableFeedItems={singleIngredients}
            availableOrigins={origins}
            fieldItemPrefix={fieldItemPrefix}
            subFieldName="singleIngredientsAdditions"
            tooltip={
              <div key="feeds-form-dialog-singleIngredients">
                {intl.formatMessage(
                  {
                    id: "BASELINE.FORM.BARN.RATION.SINGLE_INGREDIENTS.TOOLTIP",
                  },
                  { br: <br /> }
                )}
              </div>
            }
            formState={isSingleIngEdit ? formType : FormType.View}
            feedData={ingredientsAdditions}
            fieldsAppenRemoveObject={{
              fields: ingredientsAdditions,
              append: appendIngredientsAdditions,
              remove: removeIngredientsAdditions,
            }}
            allowNewIngredients={isSingleIngEdit}
          />
        )}
      {!isSingleIngEdit ? (
        <DsmButton
          variant="text"
          onClick={() => setIsSingleIngEdit(true)}
          disabled={formType === FormType.View}
          style={{ width: "120px" }}
        >
          <DsmIcon slot="before" name="general/edit-02" />
          <u>
            {intl.formatMessage({
              id: "INTERVENTION.FORM.EDIT_INPUT",
            })}
          </u>
        </DsmButton>
      ) : (
        <DsmButton
          variant="text"
          destructive
          onClick={() => resetEdit()}
          disabled={formType === FormType.View}
          style={{ width: "160px" }}
        >
          <DsmIcon slot="before" name="general/minus-circle" />
          <u>
            {intl.formatMessage({
              id: "GENERAL.CANCEL",
            })}
          </u>
        </DsmButton>
      )}
    </DsmGrid>
  )
}

export default DairyV2FeedSingleIngredientComponent;
