import { Dispatch, FC, SetStateAction } from "react";
import { FormType } from "../../common";
import { DairyV2Input } from "../../../../models/Intervention/DairyV2Intervention";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { useFormContext } from "react-hook-form";
import { unitLong } from "../../../../utils/unit-utils";
import { Spacer } from "../../../helpers/Spacer";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { Button } from "@material-ui/core";
import { DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";

interface DairyV2InputStockCowsComponentProps {
  fieldItemPrefix: string;
  formType: FormType;
  defaultStageData: DairyV2Input;
  isStockEdit: boolean;
  setIsStockEdit: Dispatch<SetStateAction<boolean>>
}

const DairyV2InputStockCowsComponent: FC<DairyV2InputStockCowsComponentProps> = ({
  fieldItemPrefix,
  formType,
  defaultStageData,
  isStockEdit,
  setIsStockEdit
}) => {
  const intl = useIntl();
  const classes: CSSClassesList = processAndStageStylesV2();
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  const fc = useFormContext<DairyV2Input>();

  const resetEdit = () => {
    fc.setValue(`${fieldItemPrefix}.animalsPresentAtStart`, "");
    fc.setValue(`${fieldItemPrefix}.animalsPresentAtEnd`, "");
    fc.setValue(`${fieldItemPrefix}.averageWeightAtStart`, "");
    setIsStockEdit(false);
  };

  return (
    <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
      {!isStockEdit ? (
        <>
          {!!fc.getValues(`${fieldItemPrefix}.animalsPresentAtStart`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.animalsPresentAtStart`}
                label={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_START' })}
                tooltip={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_START.CONTINUOUS_GROWING.TOOLTIP' })}
                type="number"
                metricUnit=""
                baseline={defaultStageData.animalsPresentAtStart}
                displayTypeSelect={false}
                disabled
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAnimalsPresentAtStart`}
              label={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_START' })}
              tooltip={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_START.CONTINUOUS_GROWING.TOOLTIP' })}
              adornment={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_START.ADORNMENT' })}
              type="number"
              defaultValue={defaultStageData.animalsPresentAtStart ?? ''}
              disabled
            />
          )}
          {!!fc.getValues(`${fieldItemPrefix}.animalsPresentAtEnd`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.animalsPresentAtEnd`}
                label={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_END' })}
                tooltip={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_END.CONTINUOUS_GROWING.TOOLTIP' })}
                type="number"
                metricUnit=""
                baseline={defaultStageData.animalsPresentAtEnd}
                displayTypeSelect={false}
                disabled
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAnimalsPresentAtEnd`}
              label={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_END' })}
              tooltip={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_END.CONTINUOUS_GROWING.TOOLTIP' })}
              adornment={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_END.ADORNMENT' })}
              type="number"
              defaultValue={defaultStageData.animalsPresentAtEnd ?? ''}
              disabled
            />
          )}
          {!!fc.getValues(`${fieldItemPrefix}.averageWeightAtStart`) ? (
            <div>
              <RowTextFieldWithMetrics
                name={`${fieldItemPrefix}.averageWeightAtStart`}
                label={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_START' })}
                tooltip={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_START.CONTINUOUS_GROWING.TOOLTIP' })}
                type="number"
                metricUnit=""
                baseline={defaultStageData.averageWeightAtStart}
                displayTypeSelect={false}
                disabled
              />
              <Spacer />
            </div>
          ) : (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAverageWeightAtStart`}
              label={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_START' })}
              tooltip={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_START.CONTINUOUS_GROWING.TOOLTIP' })}
              adornment={intl.formatMessage(
                { id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_START.ADORNMENT' },
                { unit: userUOM?.unitBarnOutputMass ? unitLong(userUOM.unitBarnOutputMass) : 'kg' }
              )}
              type="number"
              defaultValue={defaultStageData.averageWeightAtStart ?? ''}
              disabled
            />
          )}
          <div />

          <div id='button-div'
            style={{
              color: 'var(--dsm-color-neutral-darker',
              left: '0',
              display: 'flex',
              flexDirection: 'column'
            }}>

            <Button
              href=""
              style={{
                textDecoration: 'underline',
                color: '#0070BA',
                display: (!isStockEdit && formType !== FormType.View) ? 'flex' : 'none',
                placeSelf: 'start',
                textTransform: 'none',
              }}
              onClick={() => setIsStockEdit(true)}
              disabled={formType === FormType.View}
            >
              <DsmIcon
                slot="before"
                name="editor/pencil-01"
                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
              />
              {intl.formatMessage({ id: 'GENERAL.EDIT_INPUT' })}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.animalsPresentAtStart`}
              label={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_START' })}
              tooltip={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_START.CONTINUOUS_GROWING.TOOLTIP' })}
              type="number"
              metricUnit=""
              baseline={defaultStageData.animalsPresentAtStart}
              disabled={false}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.animalsPresentAtEnd`}
              label={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_END' })}
              tooltip={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_END.CONTINUOUS_GROWING.TOOLTIP' })}
              type="number"
              metricUnit=""
              baseline={defaultStageData.animalsPresentAtEnd}
              disabled={false}
            />
            <Spacer />
          </div>
          <div>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.averageWeightAtStart`}
              label={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_START' })}
              tooltip={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_START.CONTINUOUS_GROWING.TOOLTIP' })}
              type="number"
              metricUnit=""
              baseline={defaultStageData.averageWeightAtStart}
              disabled={false}
            />
            <Spacer />
          </div>
          <div />

          {isStockEdit === true && (
            <Button
              href=""
              style={{
                textDecoration: 'underline',
                color: '#FF0000',
                placeSelf: 'start',
                textTransform: 'none',
              }}
              onClick={() => resetEdit()}
              disabled={formType === 'view'}
            >
              <DsmIcon
                slot="before"
                name="general/minus-circle"
                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
              />
              {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
            </Button>
          )}
        </>
      )}
    </DsmGrid>
  )
}


export default DairyV2InputStockCowsComponent;
