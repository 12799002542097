import { FC } from "react";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { StageType } from "../../../../../../graphql/types";
import ReactHookDsmSelect from "../../../../../modules/Helpers/ReactHookDsmSelect2";
import ReactHookDsmPicker from "../../../../../modules/Helpers/ReactHookDsmDatePicker";
import { DsmGrid } from "@dsm-dcs/design-system-react";
import { AnimalProductivity } from "../../../../../../graphql/generated/blonk/cattle";

interface DairyV2InputProductivityComponentProp {
  stageType: StageType;
  fieldItemPrefix: string;
  cattleProductivity: string;
  startDate: Date | string;
}

const DairyV2InputProductivityComponent: FC<DairyV2InputProductivityComponentProp> = ({
  stageType,
  fieldItemPrefix,
  cattleProductivity,
  startDate,
}) => {
  const intl = useIntl();
  const classes: CSSClassesList = processAndStageStylesV2();

  const cattleProductivityOptions = () =>
    Object.entries(AnimalProductivity).map(([key, value]) => ({
      text: key,
      value,
    }));

  return (
    <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
      <ReactHookDsmSelect
        name={`${fieldItemPrefix}.originalCattleProductivity`}
        label={intl.formatMessage({
          id: 'SUSTELL.STAGE.DAIRY.INPUT.CATTLE_PRODUCTIVITY',
        })}
        options={cattleProductivityOptions()}
        disabled
        tooltip={intl.formatMessage({
          id: 'SUSTELL.STAGE.DAIRY.INPUT.CATTLE_PRODUCTIVITY.TOOLTIP'
        })}
        defaultValue={cattleProductivity}
      />
      <ReactHookDsmPicker
        name={`${fieldItemPrefix}.originalStartDate`}
        label={intl.formatMessage({
          id: 'SUSTELL.STAGE.DAIRY.INPUT.START_DATE',
        })}
        tooltip={intl.formatMessage({
          id: stageType === StageType.ContinuousGrowing ?
            'SUSTELL.STAGE.DAIRY.INPUT.START_DATE.CONTINUOUS_GROWING.TOOLTIP' :
            'SUSTELL.STAGE.DAIRY.INPUT.START_DATE.TOOLTIP'
        })}
        disabled
        defaultValue={startDate?.toString()}
      />
    </DsmGrid>
  )
}

export default DairyV2InputProductivityComponent;
