import { FC, useEffect, useRef, useState } from "react";
import { FormType } from "../../common";
import { DairyV2Baseline } from "../../../../models/Baseline/DairyV2Baseline";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { DairyV2DairyCowStageData, DairyV2Input, DairyV2Intervention } from "../../../../models/Intervention/DairyV2Intervention";
import { useFormContext } from "react-hook-form";
import { cloneDeep } from "lodash";
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import { StageType } from "../../../../../../graphql/types";
import { DsmGrid } from "@dsm-dcs/design-system-react";
import DairyV2InputProductivityComponent from "./DairyV2InputProductivityComponent";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";
import DairyV2InputExternalSourceComponent from "./DairyV2InputExternalSourceComponent";
import DairyV2InputInternalSourceComponent from "./DairyV2InputInternalSourceComponent";
import DairyV2InputStockCowsComponent from "./DairyV2InputStockCowsComponent";
import DairyV2InputNewAnimalsCowComponent from "./DairyV2InputNewAnimalsCowComponent";

interface DairyV2InputCowsDialogProp {
  formType: FormType;
  stageType: StageType;
  stageIndex: number;
  baseline: DairyV2Baseline;
  productionProcessName: string;
  handleCancel: (param?: string | null) => void;
}

const DairyV2InputCowsDialog: FC<DairyV2InputCowsDialogProp> = ({
  formType,
  stageType,
  stageIndex,
  baseline,
  productionProcessName,
  handleCancel,
}) => {
  const intl = useIntl();
  const classes: CSSClassesList = processAndStageStylesV2();
  const formTitle = intl.formatMessage({ id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE', });

  const fieldItemPrefix = `stages.${stageIndex}.stageData.input`;

  const currResetValue = useRef<DairyV2Input>();
  const fc = useFormContext<DairyV2Intervention>();

  const currentStageData = baseline.stages[stageIndex].stageData;
  const dairyV2InputCowsData = (currentStageData as DairyV2DairyCowStageData).input;

  const [isStockEdit, setIsStockEdit] = useState<boolean>(false);
  const [isNewAnimalsEdit, setIsNewAnimalsEdit] = useState<boolean>(false);
  const [isInternalEdit, setIsInternalEdit] = useState<boolean>(false);
  const [isExternalEdit, setIsExternalEdit] = useState<boolean>(false);

  const isInputEdit = isStockEdit || isNewAnimalsEdit || isInternalEdit || isExternalEdit;

  useEffect(() => { currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix)) });

  const handleDialogCancelClick = () => {
    if (currResetValue.current) {
      for (let [propertyName, key] of Object.entries(currResetValue.current)) {
        fc.setValue(`${fieldItemPrefix}.${propertyName}`, key);
      }
    }
    if (handleCancel) {
      handleCancel();
    }
  };

  return (
    <DialogContainer
      formVisible={true}
      handleClose={handleDialogCancelClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      datasetType="intervention"
      width={isInputEdit ? "1084px" : "784px"}
    >
      <DsmGrid className={classes.dsmGridOneColumnNoGap}>
        {dairyV2InputCowsData &&
          <DairyV2InputProductivityComponent
            stageType={stageType}
            fieldItemPrefix={fieldItemPrefix}
            cattleProductivity={dairyV2InputCowsData.cattleProductivity}
            startDate={dairyV2InputCowsData.startDate ?? ""}
          />
        }

        <p className={classes.headingTitleVariant}>
          {intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.COWS' })}
        </p>
        <DsmGrid className={classes.dsmGridOneColumnWithBorder}>
          <p className={classes.headingTitleVariant}>
            {intl.formatMessage({ id: 'SUSTELL.STAGE.CATTLE.INPUT.STOCK' })}
          </p>
          <p>
            {intl.formatMessage({ id: 'SUSTELL.STAGE.CATTLE.INPUT.STOCK_DESCRIPTION' })}
          </p>
          <DairyV2InputStockCowsComponent
            fieldItemPrefix={fieldItemPrefix}
            formType={formType}
            defaultStageData={dairyV2InputCowsData}
            isStockEdit={isStockEdit}
            setIsStockEdit={setIsStockEdit}
          />
        </DsmGrid>

        <DsmGrid className={classes.dsmGridOneColumnWithBorder}>
          <p className={classes.headingTitleVariant}>
            {intl.formatMessage({ id: 'SUSTELL.STAGE.CATTLE.INPUT.NEW_ANIMALS' })}
          </p>
          <p>
            {intl.formatMessage({ id: 'SUSTELL.STAGE.CATTLE.INPUT.NEW_ANIMALS_DESCRIPTION' })}
          </p>
          <DairyV2InputNewAnimalsCowComponent
            fieldItemPrefix={fieldItemPrefix}
            formType={formType}
            defaultStageData={dairyV2InputCowsData}
            isNewAnimalsEdit={isNewAnimalsEdit}
            setIsNewAnimalsEdit={setIsNewAnimalsEdit}
          />

          <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
            <DairyV2InputInternalSourceComponent
              formType={formType}
              stageType={stageType}
              productionProcessName={productionProcessName}
              fieldItemPrefix={fieldItemPrefix}
              defaultStageData={dairyV2InputCowsData}
              isInternalEdit={isInternalEdit}
              setIsInternalEdit={setIsInternalEdit}
            />

            <DairyV2InputExternalSourceComponent
              formType={formType}
              stageType={stageType}
              fieldItemPrefix={fieldItemPrefix}
              defaultStageData={dairyV2InputCowsData}
              isExternalEdit={isExternalEdit}
              setIsExternalEdit={setIsExternalEdit}
            />

          </DsmGrid>
        </DsmGrid>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleDialogCancelClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  )
}

export default DairyV2InputCowsDialog;
